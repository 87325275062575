@mixin font-face($font-name, $file-name, $folder-name, $font-weight: normal, $font-style: normal) {
    @font-face {
        font-weight: $font-weight;
        font-style: $font-style;
        font-family: quote($font-name);
        src:
            url($font-path + $folder-name + '/' + $file-name + '.woff2') format('woff2'),
            url($font-path + $folder-name + '/' + $file-name + '.woff') format('woff');
        font-display: swap;
    }
}
