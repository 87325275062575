.header-promo {
    height: $header-promo-height;
    overflow: hidden;

    @include media-query(small) {
        height: $header-promo-height-m;
    }

    .hero--natural {
        width: 100% !important;
    }

    .hero--natural .hero__content-wrap {
        height: $header-promo-height;
        max-width: 100%;
        padding: 0;
        
        @include media-query(small) {
            font-size: rem-calc(12);
            height: $header-promo-height-m;
        }
    }

    .hero__content,
    .slick-slide {
        margin: auto;
    }

    .slick-slide {
        height: auto;
    }

    .hero__description {
        margin-top: 0;
        max-width: 100%;
    }

    .slick-slider .slick-list {
        transform:rotate(180deg);
    }

    .slick-slider .slick-slide {
        transform:rotate(180deg);
    }

    .slick-arrow {
        display: none;
    }

    .hero-carousel:not(.slick-initialized) {
        overflow-x: hidden;
    }
}
