/*------------------------------------*\
  #TYPOGRAPHY
\*------------------------------------*/

$font-family-sans: "Newtime R", Helvetica, Arial, sans-serif;
$font-family-sans-secondary: "Newtime R", Helvetica, Arial, sans-serif;
$font-family-book: "Newtime R Book", Helvetica, Arial, sans-serif;
$font-family-heavy: "Newtime R Heavy", Helvetica, Arial, sans-serif;
$font-family-catalogue: "Catalogue", Helvetica, Arial, sans-serif;
$font-family-catalogue-italic: "Catalogue Italic", Helvetica, Arial, sans-serif;

$font-path: '../fonts/';
$font-size-base: 16px;
$letter-spacing-base: 0.04em;
$font-line-height-base: 1.5;
$refinements-offset--sm: rem-calc(80);
$refinements-offset--lg: rem-calc(160);
$minimized-nav-height: rem-calc(54);

//Set an empty default map, which is then used as a merge point in the case there are overrides in overlaid cartridges.
$color-primary: #000000 !default;
$color-grey-0: #D1D1D1;
$color-grey-1: #f8f8f8;
$color-grey-2: #e9e8e1;
$color-grey-3: #eeeeee;
$color-grey-4: #c4c4c4;
$color-grey-5: #999999;
$color-grey-6: #efefef;
$color-grey-7: #575757;
$color-grey-8: #F8F7F1;

$color-start-white: #efefe8;
$color-quickadd-bg: $color-beige;

$colors: (
    primary: $color-primary,

    beige: $color-beige,
    red: $color-error,
    //GREYSCALE COLORS
    grey-1: $color-grey-1,
    grey-2: $color-grey-2,
    grey-3: $color-grey-3,
    grey-4: $color-grey-4,
    grey-5: $color-grey-5,
    grey-7: $color-grey-7,
    start-white:$color-start-white,
    grey-mid: $color-grey-mid,
    sand: $color-sand,
    fwb-blue: $color-fwb-blue
);

$color-body-copy: $color-primary;

/*------------------------------------*\
  #BREAKPOINTS
\*------------------------------------*/

$breakpoints: (
    unit           : 0.0625rem, // 1
    smallest       : 24.3125rem, // 389
    xxsmall        : 25.8125rem, // 413
    xsmall         : 41.5rem, // 664
    small          : 47.9375rem, // 767
    msmall         : 59rem, // 944
    medium         : 63.9375rem, // 1,023
    mlarge         : 75rem, // 1,200
    large          : 90rem, // 1,440
    xlarge         : 125rem // 2000
);

$sizes: (
    0: 0,
    2: rem-calc(2),
    4: rem-calc(4),
    5: rem-calc(5),
    6: rem-calc(6),
    8: rem-calc(8),
    10: rem-calc(10),
    12: rem-calc(12),
    15: rem-calc(15),
    16: rem-calc(16),
    20: rem-calc(20),
    24: rem-calc(24),
    25: rem-calc(25),
    30: rem-calc(30),
    32: rem-calc(32),
    35: rem-calc(35),
    36: rem-calc(36),
    40: rem-calc(40),
    45: rem-calc(45),
    48: rem-calc(48),
    50: rem-calc(50),
    56: rem-calc(56),
    60: rem-calc(60),
    80: rem-calc(80),
    100: rem-calc(100),
    'auto': auto
);

$font-sizes: (
    8:  rem-calc(8),
    9:  rem-calc(9),
    10: rem-calc(10),
    12: rem-calc(12),
    14: rem-calc(14),
    16: rem-calc(16),
    18: rem-calc(18),
    20: rem-calc(20),
    22: rem-calc(22),
    24: rem-calc(24),
    25: rem-calc(25),
    27: rem-calc(27),
    30: rem-calc(30),
    32: rem-calc(32),
    40: rem-calc(40),
    50: rem-calc(50),
    60: rem-calc(60),
    70: rem-calc(70)
);

$percentages: (
    0: 0,
    10: 10%,
    20: 20%,
    25: 25%,
    30: 30%,
    33: 33%,
    40: 40%,
    45: 45%,
    48: 48%,
    50: 50%,
    55: 55%,
    60: 60%,
    66: 66%,
    67: 67%,
    70: 70%,
    75: 75%,
    80: 80%,
    90: 90%,
    100: 100%,
);

$gap-sizes: (
    4:  rem-calc(4),
    6:  rem-calc(6),
    8:  rem-calc(8),
    10:  rem-calc(10),
    16: rem-calc(16),
    24: rem-calc(24)
);

/*------------------------------------*\
  #ELEMENT-DEFAULTS
\*------------------------------------*/
$input-border-radius: 0;
$input-height: rem-calc(56);
$button-height: rem-calc(56);
$header-promo-height: rem-calc(40);
$header-promo-height-m: rem-calc(32);
$d-button-font-size: rem-calc(16);
$button-font-weight: 500;
$header-height-m: rem-calc(56);


/*------------------------------------*\
  #TOOLTIP ELEMENTS
\*------------------------------------*/
$tooltip-border-width: rem-calc(8);


/*------------------------------------*\
  #HORIZONTAL-SPACING
\*------------------------------------*/

$main-offset: rem-calc(30);
$gutter-base: rem-calc(6); // 6px
$gutter-inner: .625rem !default; //base
$gutter-inner-deci: .35rem !default;

$gutter-outer-kilo: 3.125rem !default;
$gutter-outer: 2rem !default; //base
$gutter-outer-deci: 1.25rem !default;
$gutter-outer-centi: 1rem !default;
$gutter-outer-micro: .75rem !default;


/*------------------------------------*\
  #TRANSITIONS
\*------------------------------------*/
$transition-ease-quickadd: cubic-bezier(0.455, 0.03, 0.515, 0.955) !default;

// Font Weights
$font-weight-extrabold: 900 !default;
