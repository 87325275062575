@import "~app_accelerator_core/components/buttons";

/*------------------------------------*\
  #BUTTONS
\*------------------------------------*/

button {
  letter-spacing: $letter-spacing-base;
}

.button {
    border-radius: 0;
    height: $button-height;
    font-weight: $button-font-weight;
    transition: none;
    letter-spacing: $letter-spacing-base;

    &[type=button] {
      -webkit-appearance: none;
      border-radius: 0;
    }
}

.button--primary {
    &[disabled] {
        color: $color-grey-dark;
        background-color: $color-grey-line;
        border-color: $color-grey-line;
    }  

	&:hover:not([disabled]):not(.disabled) {
		opacity: 1;
		background-color: $color-primary;
		color: $color-white;

		@media (hover: hover) {
			background-color: $color-white;
			color: $color-primary;		
		}
	}
}

.button--primary-outline {
  min-width: fit-content;
}

.button--back-to-top {
  background-color: $color-white;
  margin-top: 0;
}

.button--padding-0 {
  padding: 0;
}

.button--consent-tracking {
    height: rem-calc(35);
    font-size: rem-calc(14);
}

.button--submit-btn {
  background-color: $color-white;
  border-color: $color-white;
  color: $color-primary;

  &:hover:not([disabled]):not(.disabled) {
    opacity: 1;
    background-color: $color-primary;
    border-color: $color-primary;
    color: $color-white;
  }
}

.button--paypal {
  background-color: $color-paypal-yellow;
  border: none;
}

//SFCC's Apple Pay buttons require some extra specifity.
.button--apple-pay.dw-apple-pay-button {
    @supports (-webkit-appearance: -apple-pay-button) {
        -webkit-appearance: -apple-pay-button;
        -apple-pay-button-style: black;
    }
}

.button--minicart-applepay.set--applepay-below{
  z-index: -1;
}

.button--google-pay {
  border: rem-calc(1) solid;
  height: $button-height;
  overflow: hidden;
}

.button--quickadd {
  border: none;
}

.button--tall {
    height: $button-height;
}

.quickadd__size {
  display: inline-block;
  padding: 0 10px;
  height: 1.4375rem;
  min-width: 1.4375rem;
  color: $color-black;
  line-height: 1.5rem;
  white-space: pre;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid $color-black;
  }

  &--waitlist,
  &:disabled {
    color: $color-grey-4;
  }
}

.chip {
    font-weight: $font-weight-normal;
}

.pill {
    font-weight: $font-weight-normal;
    font-size: rem-calc(14);
}

.button--order-return {
    width: rem-calc(353);
    margin-left: auto;
    margin-right: auto;
}

.pill {
  &:hover {
    background-color: initial;
    border-color: initial;
    color: initial;
  }
}

.product-add__button_loading-dots,
.product-add__button_checkmark,
.button_loading-dots,
.button_checkmark {
    display: none;
}

@include media-query(small-up) {
    .button {
        font-size: $d-button-font-size;
        padding: 0 2em;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .button--primary__font-20 {
        font-size: $d-button-font-size;
        padding: 0;
    }
}

/*md

# Buttons

## Primary types

```html_example
<button class="button button--primary">Shop now</button>
<button class="button button--primary-outline ">Shop now</button>
```

## PDP boxes

```html_example
<div class="product-attribute__list">
	<div class="product-attribute__contents flex flex-flow-wrap">
		<button type="button" class="product-attribute__anchor anchor--size  selectable">4</button>
		<button type="button" class="product-attribute__anchor anchor--size  selectable">6</button>
		<button type="button" class="product-attribute__anchor anchor--size  unselectable">8</button>
		<button type="button" class="product-attribute__anchor anchor--size  selectable">10</button>
		<button type="button" class="product-attribute__anchor anchor--size  selectable">XXS</button>
		<button type="button" class="product-attribute__anchor anchor--size  unselectable">XS</button>
		<button type="button" class="product-attribute__anchor anchor--size  selectable">S</button>
		<button type="button" class="product-attribute__anchor anchor--size  selected">M</button>
		<button type="button" class="product-attribute__anchor anchor--size  selectable">L</button>
		<button type="button" class="product-attribute__anchor anchor--size  selectable">XL</button>
		<button type="button" class="product-attribute__anchor anchor--size  selectable">XXL</button>
		<button type="button" class="product-attribute__anchor anchor--size  selectable">XXXL</button>
	</div>
</div>
```

## Pill buttons

```html_example
    <button class="pill">Blue</button>
    <button class="pill ">Red</button>
    <button class="pill">Yellow</button>
```

 */
