$swatch-size--sm: rem-calc(20);
$swatch-size--md: rem-calc(30);
$swatch-size--lg: rem-calc(42);

.swatch--color,
.swatch--print {
    @include relative;

    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 0 0 transparent;
    background-color: $color-white;
    border: rem-calc(1) solid transparent;
    border-radius: 50%;

    &:after {
        @include vertical-align($alignHorizontal: true, $posAdj: -.125rem);

        border: none;
        border-radius: 50%;
        content: '';
    }

    &.swatch--white {
        &:before {            
            content: '';
            border-radius: 50%;
            border: rem-calc(1) solid $color-beige;
            z-index: 1;
        }
    }

    .swatch--color__link:hover &:not(.disabled),
    &:not(.disabled):hover,
    &.selected {
        &:after {
            border: rem-calc(1) solid $color-black;
        }

        &.swatch--white {
            &:before {
                border-color: transparent;
            }
        }
    }

    &.disabled:not(.selected):hover {
        &:after {
            border-color: transparent;
        }
    }

    &.disabled:not(.selected) {
        opacity: 0.2;
        border-color: transparentize($color-black, 0.25);
        cursor: auto;
    }
}

.swatch--print {
    display: block;
}

    .swatch__icon--color {
        @include absolute(top 0 left 0 right 0 bottom 0);

        margin: auto;
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

.swatch--color-small {
    width: $swatch-size--sm;
    height: $swatch-size--sm;
    flex: 0 0 1.25em;

    &:after {
        box-shadow: inset 0 0 0 .125rem $color-white;
    }

    &.selected {
        &:before {
            width: rem-calc(16);
            height: rem-calc(16);
        }
    }
}

.swatch--color-large {
    width: $swatch-size--lg;
    height: $swatch-size--lg;
    flex: 0 0 $swatch-size--lg;

    &:after {
        box-shadow: inset 0 0 0 rem-calc(3) $color-white;
    }

    &.selected {
        &:before {
            width: rem-calc(16);
            height: rem-calc(16);
        }
    }
}

.swatch--color-med {
    width: $swatch-size--md;
    height: $swatch-size--md;
    flex: 0 0 $swatch-size--md;

    &:after {
        box-shadow: inset 0 0 0 rem-calc(3) $color-grey-1;

        @include media-query(medium) {
            box-shadow: inset 0 0 0 rem-calc(3) $color-white;
        }
    }

    &.selected {
        &:before {
            width: rem-calc(16);
            height: rem-calc(16);
        }
    }
}

.swatch--color-misc {
    background: linear-gradient(45deg, yellow 50%, pink 50%);
}

.swatch--color {
    @include property-modifiers($colors, background-color, '-');
}
