[data-vip-element] {
    display: none;

    &.active {
        display: inline-flex;
    }

    &.vipIcon {
        margin-left: rem-calc(8);
    }
}


