$anchor-size--sm: rem-calc(40);

.product-attribute__anchor {
    position: relative;
    border: 1px solid $color-black;
    font-size: rem-calc(14);
    display: flex;
    align-items: center;
    justify-content: center;
    height: $anchor-size--sm;
    min-width: rem-calc(40);
    padding: 0 rem-calc(10);
    
    &--mobile-quickadd {
        min-width: 0;
        width: 100%;
        height: rem-calc(48);
        display: block;
        margin: 0 !important;
        padding: 0;
    }
    @media (hover: hover) {
        &:hover:not(.selected):not(.unselectable):not(.product-attribute__anchor--mobile-quickadd) {
            background: $color-black;
            color: $color-white;
        }
    }


    &.disabled,
    &.unselectable {
        color: $color-grey-dark;
        background-color: $color-white;
        border: 1px dashed $color-grey-dark;
        cursor: pointer;
        text-decoration: line-through;
    }

    &.selected {
        color: $color-white;
        border-color: $color-body-copy;
        background-color: $color-body-copy;
    }

    &.waitlist {
        background: $color-grey-1;
        border: 1px solid $color-grey-1;
        color: $color-grey-dark;
        cursor: pointer;
        text-decoration: none;

        &.selected {
            box-shadow: none;
            border-color:  $color-black;
            background: $color-black;
            color: $color-white;
        }
    }
}

@include media-query(medium-up) {
    .product-attribute__anchor {

        &:hover:not(.selected):not(.unselectable) {
            box-shadow: none;
            background: $color-black;
            color: $color-white;
        }

        &.waitlist {
            background: $color-grey-1;
            border: 1px solid $color-grey-1;
            color: $color-grey-dark;
            cursor: pointer;

            &.selected,
            &:hover {
                box-shadow: none;
                background: $color-black;
                color: $color-white;
            }
        }
    }
    .product-size-attribute__anchor {
        @include relative;

        font-size: rem-calc(14);
        border: 1px solid transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        height: rem-calc(23);
        min-width: rem-calc(23);
        padding: rem-calc(2) rem-calc(10) 0;

        &.disabled,
        &.unselectable {
            color: $color-grey-4;
        }

        &:hover {
            border-color: $color-black;
        }
    }
}
