    .header__utility-anchor {
        position: relative;
        color: $header-nav-color;
        align-items: center;
        padding-right: rem-calc(16);

        .icon--magnifying-glass {
            width: rem-calc(19.15);
            height: rem-calc(19.2);
            margin-bottom: rem-calc(3);
        }

        .icon--shopping-bag {
            width: rem-calc(32);
            height: rem-calc(32);
            margin-top: rem-calc(4);
            margin-right: 0;
            padding: rem-calc(6.4);
        }
    }

    .header__utility-item {
        font-size: rem-calc(16);
    }

    .header__utility-item__icon {
        margin-top: rem-calc(-3);
        margin-right: rem-calc(5);
    }

    .header__utility-badge {
        min-width: 1rem;
        height: 1rem;
        padding-right: .25em;
        padding-left: .25em;
        background: none;
        border-radius: 0;
        color: $color-primary;
        font-size: 1rem;
        font-weight: $font-weight-normal;
        margin-left: .25rem;
        position: static;
    }

    .header__utility-item__quantity {
        .set--no-items & {
            display: none;
        }
    }

    .header__utility-item__spacer {
        width: rem-calc(5);
    }

    .header__utility-item__spacer-quantity {
        width: rem-calc(3);
    }

@include media-query(small-up-to-medium) {
    .header__utility-item {
        &:not(:last-child) {
            margin-right: 1rem;
        }
    }
}

@include media-query(medium) {
    .header__utility-item {
        margin-bottom: rem-calc(5);

        &:not(:last-child) {
            margin-right: .75rem;
        }

        &.header__search {
            margin-bottom: 0;
            margin-right: rem-calc(4);
        }

        &.header__minicart {
            margin-bottom: 0;
        }

        .header-flyout__secondary & {
            display: flex;
            color: $color-grey-dark;    
            font-size: rem-calc(14);
            align-items: flex-end;
            margin-bottom: rem-calc(4);

            .icon--mobile-nav {
                width: rem-calc(24);
                height: rem-calc(24);
            }

            .icon--fwb {
                margin: 0 0 rem-calc(2) rem-calc(4);
            }
        }
    }

    .header__utility-item.header-flyout__secondary-link .link--secondary {
        font-size: rem-calc(16);

        &:hover {
            text-decoration: none;
        }
    }

    .header__utility-item .header__utility-anchor--search {
        transform: translateX(0);
    }

    .header__utility-item__quantity {
        margin-bottom: rem-calc(-6);
        margin-left: rem-calc(4);
    }

    .header__utility-item__icon {
        margin-top: 0;
    }

    .header__utility-anchor {
        .icon--magnifying-glass {
            margin-bottom: 0;
        }

        .icon--shopping-bag {
            width: rem-calc(19.2);
            height: rem-calc(19.2);
            margin: 0;
            padding: 0;
        }
    }
}

@include media-query(medium-up) {
    .header__utility-item {
        display: flex;
        margin-left: rem-calc(20);
        flex-direction: row-reverse;

        &:not(:last-child) {
            margin-right: 1.625rem;
        }

        &.header__search {
            margin-left: 0;
        }
    }

    .header__utility-item:not(:last-child) {
        margin-right: 0;
    }

    .header__utility-anchor--with-icon:hover:after {
        content: '';
        position: absolute;
        width: 100%;
        bottom: rem-calc(4.5);
        border-bottom: rem-calc(1) solid $color-black;
    }

    .header__utility-anchor {
        .icon--magnifying-glass {
            height: rem-calc(14);
            width: rem-calc(14);
            margin-bottom: 0;
        }
        .icon--shopping-bag {
            width: rem-calc(20);
            height: rem-calc(20);
            margin-top: rem-calc(-3);
            margin-right: 0;
            padding: rem-calc(4);
        }
        .icon--heart {
            width: rem-calc(13.77);
            height: rem-calc(12);
        }
        .icon--fwb {
            width: rem-calc(12);
            height: rem-calc(12);
        }
    }
}
