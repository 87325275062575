.breadcrumbs {
    margin-bottom: 1rem;

    &.account-breadcrumbs {
        margin-bottom: rem-calc(24);
        flex-wrap: wrap;
    }

    .pdp__breadcrumbs & {
        display: block;
        text-align: center;
    }
}

.breadcrumbs--spaced {
    margin-top: 1rem;
}

.breadcrumbs__item {
    .breadcrumbs__separator {
        color: $color-grey-dark;
    }
}

.breadcrumbs__anchor {
    font-size: $font-size-base;
    color: $color-grey-dark;
    font-family: $font-family-book;
    font-weight: 400;
}

.breadcrumbs__container--no-gutters {
    .breadcrumbs {
        margin: 0;
    }
}

    .breadcrumbs__separator {
        display: flex;
        margin: 0 rem-calc(8);
        font-size: rem-calc(16);
    }

.breadcrumbs__anchor:last-child {
    line-height: 1;
    color: $color-black;
    font-family: $font-family-sans;

    &:hover{
        text-decoration: none;
    }
}