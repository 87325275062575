@import "~app_accelerator_core/modules/moduleCommons";

.set--text-overlay-large [class*="heading-type"] {
    @include media-query(medium) {
        font-family: $font-family-catalogue;
        letter-spacing: unset;
    }
}

.set--text-overlay-small [class*="heading-type"] {
    @include media-query(small) {
        font-family: $font-family-catalogue;
        letter-spacing: unset;
    }
}

.component-h-align--right {
    margin-left: auto;
}

.component-h-align--left {
    margin-right: auto;
}

.video-asset--natural {
    video.vjs-tech {
        height: auto;
        object-fit: contain;
    }
}


.product-grid {
    &:not(.set--show-all-products) {
            .product-grid__item--hideable {
                &:not(.slick-slide) {
                    display: none;
                }
            }
        }
    }


    .product-grid__cta-more {
        &.set--show-all-products {
            .product-grid__cta-label--show {
                display: none;
            }
        }

        &:not(.set--show-all-products) {
            .product-grid__cta-label--hide {
                display: none;
            }
        }
    }

@include media-query(small) {
    .module-container {
        .module-grid.row.stack-left {
            flex-direction: column-reverse;
        }
        .product-grid__container {
            margin-bottom: rem-calc(32);
            .product-grid__cta-container {
                display: none;
            }
            .product-grid__item.slick-slide {
                height: auto;
            }
            .slick-dots {
                width: 100%;
                button {
                    width: rem-calc(16);
                }
            }
        }
    }

    .product-grid__cta-more {
        &.hidden-xs-down {
            display: none;
        }
    }

    .pd-grid-tile {
        .product-tile__mobile-quickadd-button {
            display: none;
        }   
    }

    .module-grid--free-sliding {
        padding-bottom: 0;

        &::-webkit-scrollbar {
            display: none;
        }
    }
}

// Avoid flash of unstyled modal content
.window-modal {
    position: fixed;

    &:not(.modal-active) {
        @include set-invisible();
    }
}

.modal-container--static {
    display: none;
}

[data-mouse-trail] {
    @include fixed(top 0 left 0);
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 99;
}

[data-mouse-trail] * {
    position: absolute;
    will-change: transform;
}