@import "~app_accelerator_core/modules/hero";

.hero--size-large {
    .hero__cta {
        font-size: rem-calc(18);
    }
}

.hero--size-regular {
    .hero__cta {
        font-size: rem-calc(16);
    }
}

.hero {
    .content-cta-overlay {
        z-index: 2;
    }
}

.hero--size-small {
    .hero__cta {
        font-size: rem-calc(14);
    }
}

.hero__img {
    width: 100%;
}

@include media-query(small) {
    .hero {
        .hero__content-wrap {
            padding-left: rem-calc(20);
            padding-right: rem-calc(20);
        }
    }
}

@include media-query(small-up) {
    .hero--size-large {
        .hero__cta {
            font-size: rem-calc(24);
        }
    }

    .hero--size-reular {
        .hero__cta {
            font-size: rem-calc(20);
        }
    }

    .hero--size-small {
        .hero__cta {
            font-size: rem-calc(14);
        }
    }

}
