/**
 * Contains global styles for the header.
 * This stylesheet should not include component specific styles.
 */

.header-container {
    .set--header-blend & {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
    }

    &.set--overlay {
        .header-promo {
            z-index: 1;
        }
    }

    &:not(.set--overlay) {
        .header-promo {
            transition: z-index 0s $transition-ease-default $transition-speed-fast;
        }
    }
}

.header {
    z-index: 3;
    position: relative;
    background-color: $header-nav-bg;
    transition-property: background-color, color, box-shadow;
    transition-duration: $transition-speed-slow;
    transition-timing-function: $transition-ease-bezier;
    box-shadow: 0 .0625rem 0 0 transparentize($color-black, .85);
    cursor: initial;

    &:not(.header--no-menu) {
        box-shadow: 0 .0625rem 0 0 transparentize($color-black, .85);
    }

    .set--header-blend & {
        //Sticky display "always" vs "scroll" require different class states to determine when to display as "blended".
        //This is because the plugin will set the header as as "fixit--active" as soon as it touches the viewport's top,
        //which is not desired when using the sticky on "scroll" behavior.
        &.header--sticky-always:not(.fixit--active),
        &.header--sticky-scroll:not(.fixit--scroll-direction-change) {
            &:not(:hover):not(:focus-within):not(.focus-within) {
                background-color: transparent;
                color: $color-white;
                box-shadow: inset 0 -.0625rem 0 0 transparent;
            }
        }
    }

    //Styles for when header should diplay sticky at all times.
    &.header--sticky-always {
         &.fixit--active {
            position: fixed;
            top: 0;
            width: 100%;
        }
    }

    //Alternate styles for when the header should only display when scrolling back UP.
    &.header--sticky-scroll {
        &.fixit--active {
            position: absolute;
            width: 100%;
            //Intentionally override transition properties here to prevent jumping when header becomes un-fixed.
            transition-property: margin, background-color, color, box-shadow;

            &:not(.fixit--scroll-direction-change) {
                bottom: 0;
            }
        }

        &.fixit--scroll-direction-change {
            position: fixed;
            top: 0;
        }

        //Use FixIt's state classes to only display the navigation when scrolling up.
        //Separate margin-top is used on desktop and mobile for the hidden state.
        &.fixit--scrolled {
            &.fixit--scroll-up {
                margin-top: 0;
            }
        }
    }
}

    .header__logo,
    .header__hamburger-trigger  {
        color: $header-nav-color;
    }

    .header__logo {
        height: 1.875rem;
        //Ensures width is maintained on older browsers (IE11).
        flex-grow: 1;
        color: $color-primary;
    }

        .header__logo-img {
            width: 100%;
            fill: currentColor;
        }

    .header__skip-to-main {
        position: absolute;
        top: 1rem;
        left: 2rem;
        z-index: 1000;

        &:not(:focus) {
            pointer-events: none;
            opacity: 0;
        }
    }

.header-promo {
    position: relative;
    //Prevent other contents on the page from overlapping this element.
    z-index: 3;
}

@include media-query(small-up) {
    .header {
        //Use FixIt's state classes to hide the navigation when scrolling down.
        &.fixit--scrolled {
            &.fixit--scroll-down:not(:hover):not(:focus-within):not(.focus-within) {
                //Avoid using transform to prevent some funky issues with display.
                //This is a small sacrifice, but the header will always have a standard height anyway.
                //Adjust if necessary (separate height is used in mobile).
                // transform: translateY(-100%);
                &.header--stacked {
                    margin-top: -7.5rem;
                }

                &.header--standard {
                    margin-top: -6rem;
                }
            }
        }
    }

    .header--no-menu {
        padding-top: .5rem;
        padding-bottom: .5rem;
    }

    .header--stacked {
        padding-top: 1.25rem;

        .header-flyout__list {
            justify-content: center;
        }
    }

    .header__logo {
        max-width: 10rem;
        flex-shrink: .8;
    }
}

@include media-query(small) {
    .header {
        //Use FixIt's state classes to hide the navigation when scrolling down.
        &.fixit--scrolled {
            &.fixit--scroll-down:not(:hover):not(:focus-within):not(.focus-within) {
                //Avoid using transform to prevent some funky issues with display.
                //This is a small sacrifice, but the header will always have a standard height anyway.
                //Adjust if necessary (separate height is used in mobile).
                // transform: translateY(-100%);
                margin-top: -5rem;
            }
        }
    }

    .header__main {
        padding-top: .5rem;
        padding-bottom: .5rem;
    }

    .header__logo {
        max-width: 8rem;
        margin: auto;
    }
}
