.tab-nav {
    @include relative;

    display: flex;
    margin-bottom: rem-calc(30);
}

.tab-nav--login {
    justify-content: center;
}


    .tab-nav__item {
        padding-bottom: rem-calc(3) rem-calc(20);
        margin: rem-calc(4) rem-calc(20);
        text-align: center;
        cursor: pointer;
        align-items: center;

        &:first-child {
            padding-left: 0;
        }
    }

.product-footer {
    .tab-nav__item {
        &.toggle--active,
        .tab-nav__item-check.toggle--active + & {
           border-bottom: 2px solid $color-black;
        }
    }
}

.tab-nav__item--login {
    font-size: rem-calc(16);
    text-decoration: underline;
    line-height: rem-calc(24);
    font-weight: 500;
    padding: rem-calc(8) 0 0 0;
}



    .tab-nav__item-check {
        position: absolute;
        opacity: 0;
    }

.tab-content {
    @include relative;

    overflow: hidden;
}

    .tab-content__panel {
        &:not(.toggle--active) {
            display: none;
        }
    }

    .tab-content__panel--animated {
        animation-duration: 1s;
        animation-timing-function: $transition-ease-bezier;

        &:not(.toggle--active) {
            display: none;

            animation-name: fade-out;
        }

        &.toggle--active {
            animation-name: fade-in;
        }
    }

.accordion {
    border-bottom: 1px solid $color-black;
}

.accordion__item {
    border-top: 1px solid $color-black;
}

.accordion__title {
    align-items: center;
    display: flex;
    height: rem-calc(56);
    justify-content: space-between;
    align-content: center;
    width: 100%;
}

.toggle--active.accordion__item .accordion__icon {
    margin: rem-calc(-10) rem-calc(2) 0 0;
    transform: rotate(180deg);
}

.accordion__content {
    padding-bottom: rem-calc(30);
}

.accordion__content:not(.toggle--active) {
    display: none;
}

@include media-query(medium-up) {
    .tab-nav {
        justify-content: center;
    }

        .tab-nav__item {
            &:first-child {
                margin-left: rem-calc(10);
            }
        }
}

@keyframes fade-in {
    0%, 30% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade-out {
    0%, 30% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.paymentAsset {
    color: #ff0000;
    margin: 5px;
    padding: 5px;
}

.nav-tabs__payment-options {
    padding-left: 0;

    .tab-nav__item {
        &:first-child {
            padding-left: 0;
        }
    }

    .payment-options {
        padding: rem-calc(20) rem-calc(12);
        margin: 0;
        height: auto;
        cursor: pointer;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &:not(:first-child) {
            border-top: rem-calc(1) solid $color-grey-line;
        }

        &.toggle--active {
            height: auto;
            justify-content: center;
            padding-bottom: rem-calc(16);
        }

        .form-check-label--radio {
            max-height: rem-calc(24);
            width: 100%;
            display: flex;
            align-items: center;
            cursor: pointer;
        }
    }

}

.afterpay-option {
    vertical-align: middle;
}

/*md
@no-stat

## Accordion


```html_example
 <div class="t-set--w-40 font-size--14">
        <div class="accordion">
           <div class="accordion__item">
              <button type="button" class="accordion__title" data-toggle='{"target": ".js-pdp-details", "parent": ".accordion__item", "persist": true}'>
              <span>Product details &amp; fit</span>
              <span class="icon accordion__icon font-size--14" aria-hidden="true"></span>
              </button>
              <div class="accordion__content js-pdp-details">
                 <p>Model is wearing a M</p>
                 <p>Height: 5'10"</p>
                 <p>Waist: 24"</p>
                 <p>Hips: 34"</p>
                 <p>Bust: 32"</p>
              </div>
           </div>
           <div class="accordion__item">
              <button type="button" class="accordion__title" data-toggle='{"target": ".js-pdp-sustain", "parent": ".accordion__item", "persist": true}'>
              <span>Sustainability impact</span>
              <span class="icon accordion__icon font-size--14" aria-hidden="true"></span>
              </button>
              <div class="accordion__content js-pdp-sustain">
                 <ul class="list--reset margin-b--15">
                    <li>
                       <span class="icon font-size--14" title="xe019"></span>
                       60 lbs. of carbono dioxide savings
                    </li>
                    <li>
                       <span class="icon font-size--16 padding-r--5" title="xe020"></span>
                       150 gal. of water savings
                    </li>
                    <li>
                       <span class="icon font-size--16 padding-r--5" title="xe021"></span>
                       1.2 lbs. of waste savings
                    </li>
                 </ul>
                 Sustainability made in Turkey
              </div>
           </div>
           <div class="accordion__item">
              <button type="button" class="accordion__title" data-toggle='{"target": ".js-pdp-care", "parent": ".accordion__item", "persist": true}'>
              <span>Fabric &amp; care</span>
              <span class="icon accordion__icon font-size--14" aria-hidden="true"></span>
              </button>
              <div class="accordion__content js-pdp-care">
                 <div class="margin-b--15">
                    This is a lightweight georgette fabric with a dry handfeel - 100% Viscose.
                 </div>
                 <div class="margin-b--15">
                    Viscose – aka rayon – is a man-made cellulosic fiber made from wood pulp. We’re committed to ensuring all our forest-based products come from sustainably managed forests. That’s why we work with the non-profit group Canopy to help drive positive change for all our forest products.
                 </div>
                 <div class="margin-b--15">
                    This is a lightweight linen fabric - 100% linen
                 </div>
              </div>
           </div>
           <div class="accordion__item">
              <button type="button" class="accordion__title" data-toggle='{"target": ".js-pdp-share", "parent": ".accordion__item", "persist": true}'>
              <span>Share</span>
              <span class="icon accordion__icon font-size--14" aria-hidden="true"></span>
              </button>
              <div class="accordion__content js-pdp-share">
                 <!-- dwMarker="linclude" dwTemplateTitle="/default/product/components/socialIcons.isml (org_reformation)" dwTemplateURL="http://localhost:60606/target=/org_reformation/cartridge/templates/default/product/components/socialIcons.isml" -->
                 <div class="product-common__social">
                    <ul class="product-common__social-list flex flex-align-center list--reset">
                       <li class="product-common__social-item margin-r--15">
                          <a href="https://www.facebook.com/sharer/sharer.php?=undefined&amp;u=https%3A%2F%2Fdevelopment-na01-thereformation.demandware.net%2Fs%2Freformation-us%2F25589408M.html" class="link link--flex font-size--20 product-common__social-anchor--facebook" aria-label="Facebook, opens in a new window" data-social-share="facebook" target="_blank">
                          <span class="icon" title="&amp;#xe036;">
                          
                          </span>
                          </a>
                       </li>
                       <li class="product-common__social-item margin-r--15">
                          <a href="https://www.pinterest.com/pin/create/button?=undefined&amp;description=Tie%20Front%20Animal%20Print%20Dress&amp;url=https%3A%2F%2Fdevelopment-na01-thereformation.demandware.net%2Fs%2Freformation-us%2F25589408M.html" class="link link--flex font-size--20 product-common__social-anchor--pinterest" aria-label="Pinterest, opens in a new window" data-social-share="pinterest" target="_blank">
                          <span class="icon" title="&amp;#xe037">
                          
                          </span>
                          </a>
                       </li>
                       <li class="product-common__social-item margin-r--15">
                          <a href="https://twitter.com/intent/tweet?=undefined&amp;text=Tie%20Front%20Animal%20Print%20Dress&amp;url=https%3A%2F%2Fdevelopment-na01-thereformation.demandware.net%2Fs%2Freformation-us%2F25589408M.html" class="link link--flex font-size--20 product-common__social-anchor--twitter" aria-label="Twitter, opens in a new window" data-social-share="twitter" target="_blank">
                          <span class="icon" title="&amp;#xe038">
                          
                          </span>
                          </a>
                       </li>
                    </ul>
                 </div>
              </div>
           </div>
        </div>
    </div>
```

## Tabbed content

```html_example
<div class="tabbed-interface toggle--active" data-motion='{"properties": "opacity"}' data-tabbed-interface="container">
    <nav class="tab-nav component-custom-width">
        <button type="button" class="tab-nav__item toggle--active font-size--18"
            data-tabbed-interface="tab-nav-1"
            data-toggle='{"target": "[data-tabbed-interface=tab-content-1]", "parent": "[data-tabbed-interface=container]", "auto": true, "activeClass": "toggle--active", "persist": true, "unsetSelf": false, "siblingSelector": "[data-tabbed-interface*=tab-nav]", "customEvents": {"afterSet": {"name": "motion:trigger"}}}' role="tab">
        Product Search
        </button>
        <button type="button" class="tab-nav__item font-size--18"
            data-tabbed-interface="tab-nav-2"
            data-toggle='{"target": "[data-tabbed-interface=tab-content-2]", "parent": "[data-tabbed-interface=container]", "activeClass": "toggle--active", "persist": true, "unsetSelf": false, "siblingSelector": "[data-tabbed-interface*=tab-nav]", "customEvents": {"afterSet": {"name": "motion:trigger"}}}' role="tab">
        Content Search
        </button>
    </nav>
    <div class="tab-content">
        <div class="tab-content__panel--animated toggle--active" role="tabpanel" data-tabbed-interface="tab-content-1" data-tab-description="tab-content-1">
            Content of product search tab
        </div>
        <div class="tab-content__panel--animated" role="tabpanel" data-tabbed-interface="tab-content-2" data-tab-description="tab-content-2">
           Content of another tab goes here
        </div>
    </div>
</div>
```

 */
