@import "~app_accelerator_core/components/icons";

.icon {
  width: rem-calc(14);
  height: rem-calc(14);
}

.headervip {
  display: inline;
  width: rem-calc(24);
  height: rem-calc(24);
  margin: rem-calc(10);
}

.account-icon {
  display: inline-block;
  margin-right: rem-calc(10);

  img {
    width: rem-calc(20);
    height: auto;
    position: relative;
    top: rem-calc(-2);
  }
}

.icon--rotate-90 {
    margin-right: rem-calc(4);
    transform: rotate(-90deg);
}

svg.icon--carat-down {
  width: rem-calc(14);
  height: rem-calc(8);
}

svg.icon--chevron-right {
  width: rem-calc(6.87);
  height: rem-calc(12);
  margin-bottom: rem-calc(3);
}

svg.icon--4-panel-grid {
  aspect-ratio: 5/3;
  width: auto;
  max-width: rem-calc(24);
}

svg.icon--2-panel-grid {
  aspect-ratio: 13/12;
  width: auto;
  max-width: rem-calc(16);
}

svg.icon--cloud {
  width: rem-calc(19.2);
  height: rem-calc(12.1);
  margin-bottom: rem-calc(-1);
}

svg.icon--close {
  width: rem-calc(12);
  height: rem-calc(12);
}

svg.icon--pin {
  width: rem-calc(9);
  height: rem-calc(12);
}

svg.icon--exclamation {
  width: rem-calc(12);
  height: rem-calc(12);
}

svg.icon--trashcan {
  width: rem-calc(12);
  height: rem-calc(16);
}

svg.icon--giftcard {
  width: rem-calc(16);
  margin-bottom: rem-calc(-1);
}

svg.icon--question-mark {
  width: rem-calc(10);
  height: rem-calc(10);
}

svg.icon--fwb {
  width: rem-calc(19.2);
  height: rem-calc(19.2);
  &.hover {
    cursor: pointer;
  }
}

.notification__alert-close {
  .icon {
    width: rem-calc(12);
    height: rem-calc(12);
  }
}

.icon--loader {
  animation: rotate $transition-speed-slow infinite linear;
}

.icon--return {
  width: rem-calc(30);
  height: rem-calc(30);
}

.icon--social {
  width: rem-calc(20);
  height: rem-calc(20);
}

.icon--leaf {
  width: rem-calc(11);
  height: rem-calc(16);
}

.icon--lg {
  width: rem-calc(24);
  height: rem-calc(24);
}

/*md

# Icons

```html_example
<div class="styleguide__section--icons">
    <div> <code>&amp;#xe000;</code> Hamburger Menu <span class="icon" title="xe000"></span></div>
    <div> <code>&amp;#xe001;</code> Shopping Bag <span class="icon" title="xe001"></span></div>
    <div> <code>&amp;#xe002;</code> Magnifying Glass <span class="icon" title="xe002"></span></div>
    <div> <code>&amp;#xe003;</code> Zoom Out <span class="icon" title="xe003"></span></div>
    <div> <code>&amp;#xe004;</code> Zoom In <span class="icon" title="xe004"></span></div>
    <div> <code>&amp;#xe005;</code> Cross-Close <span class="icon" title="xe005"></span></div>
    <div> <code>&amp;#xe006;</code> Carat Up <span class="icon" title="xe006"></span></div>
    <div> <code>&amp;#xe007;</code> Carat Down <span class="icon" title="xe007"></span></div>
    <div> <code>&amp;#xe008;</code> Chevron Left <span class="icon" title="xe008"></span></div>
    <div> <code>&amp;#xe009;</code> Chevron Right <span class="icon" title="xe009"></span></div>
    <div> <code>&amp;#xe010;</code> Plus <span class="icon" title="xe010"></span></div>
    <div> <code>&amp;#xe011;</code> Minus <span class="icon" title="xe011"></span></div>
    <div> <code>&amp;#xe012;</code> Dot <span class="icon" title="xe012"></span></div>
    <div> <code>&amp;#xe013;</code> Check <span class="icon" title="xe013"></span></div>
    <div> <code>&amp;#xe014;</code> Heart <span class="icon" title="xe014"></span></div>
    <div> <code>&amp;#xe015;</code> Heart Filled <span class="icon" title="xe015"></span></div>
    <div> <code>&amp;#xe016;</code> 4 Panel Grid <span class="icon" title="xe016"></span></div>
    <div> <code>&amp;#xe017;</code> 2 Panel Grid <span class="icon" title="xe017"></span></div>
    <div> <code>&amp;#xe018;</code> Ruler <span class="icon" title="xe018"></span></div>
    <div> <code>&amp;#xe019;</code> Cloud <span class="icon" title="xe019"></span></div>
    <div> <code>&amp;#xe020;</code> Drop <span class="icon" title="xe020"></span></div>
    <div> <code>&amp;#xe021;</code> Trashcan <span class="icon" title="xe021"></span></div>
    <div> <code>&amp;#xe022;</code> Pin <span class="icon" title="xe022"></span></div>
    <div> <code>&amp;#xe023;</code> Box <span class="icon" title="xe023"></span></div>
    <div> <code>&amp;#xe024;</code> Question Circle <span class="icon" title="xe024"></span></div>
    <div> <code>&amp;#xe025;</code> Exclamation Circle <span class="icon" title="xe025"></span></div>
    <div> <code>&amp;#xe026;</code> Envelope <span class="icon" title="xe026"></span></div>
    <div> <code>&amp;#xe027;</code> Clock <span class="icon" title="xe027"></span></div>
    <div> <code>&amp;#xe028;</code> Eye <span class="icon" title="xe028"></span></div>
    <div> <code>&amp;#xe029;</code> Mobile Phone <span class="icon" title="xe029"></span></div>
    <div> <code>&amp;#xe030;</code> Figure <span class="icon" title="xe030"></span></div>
    <div> <code>&amp;#xe031;</code> Lock <span class="icon" title="xe031"></span></div>
    <div> <code>&amp;#xe032;</code> Star <span class="icon" title="xe032"></span></div>
    <div> <code>&amp;#xe033;</code> Star Rilled <span class="icon" title="xe033"></span></div>
    <div> <code>&amp;#xe034;</code> Mask <span class="icon" title="xe034"></span></div>
    <div> <code>&amp;#xe035;</code> Instagram <span class="icon" title="xe035"></span></div>
    <div> <code>&amp;#xe036;</code> Facebook <span class="icon" title="xe036"></span></div>
    <div> <code>&amp;#xe037;</code> Pinterest <span class="icon" title="xe037"></span></div>
    <div> <code>&amp;#xe038;</code> Twitter <span class="icon" title="xe038"></span></div>
    <div> <code>&amp;#xe039;</code> Youtube <span class="icon" title="xe039"></span></div>
    <div> <code>&amp;#xe040;</code> Arrow Up <span class="icon" title="xe040"></span></div>
    <div> <code>&amp;#xe041;</code> Arrow Up Right <span class="icon" title="xe041"></span></div>
    <div> <code>&amp;#xe042;</code> Arrow Right <span class="icon" title="xe042"></span></div>
    <div> <code>&amp;#xe043;</code> Arrow Down Right <span class="icon" title="xe043"></span></div>
    <div> <code>&amp;#xe044;</code> Arrow Down <span class="icon" title="xe044"></span></div>
    <div> <code>&amp;#xe045;</code> Arrow Down Left <span class="icon" title="xe045"></span></div>
    <div> <code>&amp;#xe046;</code> Arrow Left <span class="icon" title="xe046"></span></div>
    <div> <code>&amp;#xe047;</code> Arrow Up Left <span class="icon" title="xe047"></span></div>
</div>
```

 */
