$color-primary: #000000;
$color-body-copy: $color-primary;

$color-grey-1: #f8f8f8;
$color-grey-2: #e9e8e1;
$color-grey-3: #eeeeee;
$color-grey-4: #c4c4c4;
$color-grey-5: #999999;
$color-grey-mid: #757575;
$color-grey-dark: #575757;
$color-grey-line: #d1d1d1;


$color-red: #e60000;
$color-blue: #4D96E7;
$color-green: #566A27;
$color-yellow: #E7B04D;
$color-beige: #EEECE4;
$color-sand: #FAF9F7;
$color-fwb-blue: #0A1A69;
$color-fwb-beige: #F8F7F1;

$color-paypal-yellow: #FFC43A;

$color-error-light: #ffece5;
$color-error: #930000;

$colors: ();

/*md
@no-stat

# Scheme

## Greyscale

```html_example
    <div class="styleguide__section-colors">
        <div class="bg--white">
            <span>$white</span>
            #ffffff
        </div>
        <div class="bg--grey-1">
            <span>$grey-1</span>
            #f8f8f8
        </div>
        <div class="bg--grey-2">
            <span>$grey-2</span>
            #e9e8e1
        </div>
        <div class="bg--grey-3">
            <span>$grey-3</span>
            #eeeeee
        </div>
        <div class="bg--grey-4">
            <span>$grey-4</span>
            #c4c4c4
        </div>
        <div class="bg--grey-5">
            <span>$grey-5</span>
            #999999
        </div>
        <div class="bg--black text-color--white">
            <span>$black</span>
            #000000
        </div>
    </div>
```

## Colors

```html_example
    <div class="styleguide__section-colors">
        <div class="bg--beige">
            <span>$beige</span>
            #f5f5dc
        </div>
        <div class="bg--green text-color--white">
            <span>$green</span>
            #00503f
        </div>
        <div class="bg--blue text-color--white">
            <span>$blue</span>
            #4D96E7
        </div>
        <div class="bg--red text-color--white">
            <span>$red</span>
            #e60000
        </div>
        <div class="bg--start-white text-color--black">
            <span>$start-white</span>
            #efefe8
        </div>
    </div>
```

*/
