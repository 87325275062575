.page {
    min-height: 100vh;
    justify-content: space-between;

    -webkit-tap-highlight-color: transparentize($color-primary, 0.8);
}

  .main {
      min-height: breakpoint(xxsmall);
      margin: rem-calc(24) 0 auto;

      &--checkout {
          margin-top: 0;
      }
  }

  .main--spaceless {
      margin-top: 0;
  }

    .main--refund {
        @include media-query(small) {
            margin-top: 0;
            padding-top: 0 !important;
        }
    }

.container {
    max-width: breakpoint(large);
    margin-right: auto;
    margin-left: auto;
    padding-right: $gutter-outer-micro;
    padding-left: $gutter-outer-micro;
}

[class*="max-width--"] {
    margin-right: auto;
    margin-left: auto;
    padding: 0 rem-calc(15);
}

.max-width {
    @include property-modifiers($breakpoints, max-width);
    max-width: breakpoint(xsmall);
}

@include media-query(small-up) {
    .page {
        overflow: visible;
        display: flex;
        flex-direction: column;
    }

    .main {
        width: 100%;

    }

    .container {
        padding-right: $gutter-outer;
        padding-left: $gutter-outer;
    }

    [class*="max-width--"] {
        padding: 0 rem-calc(32);
    }
}
@include media-query(xlarge-up) {
    [class*="max-width--"] {
        padding: 0;
    }
}