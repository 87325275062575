
.content-tile {
    position: relative;

    .content-overlay--display-hover {
        opacity: 0;
    }

    &:hover {
        .content-overlay--display-hover {
            opacity: 1;
        }
    }

    .product-tile {
        &.text-align--center {
            .price {
                justify-content: center;
            }
        }
    }
}

.content-tile--ratio-natural-inherit,
.content-tile--ratio-inherit {
    flex-grow: 1;
}

.content-tile--ratio-natural-inherit {
    .content-tile__content-wrap {
        flex-grow: 1;
    }
}

    .content-tile__aspect-ratio--has-media {
        position: relative;
    }

    .content-tile__aspect-ratio--natural {
        //Fixes IE11 issue which renders overly tall elements if the image is large enough.
        flex-shrink: 0;
    }

    .content-tile__aspect-ratio--natural-inherit {
        //Safari requires over-specificity of the DOM containers that should grow.
        .content-tile__media,
        .content-tile__media > *,
        &.content-tile__aspect-ratio--has-media {
            display: flex;
            flex-grow: 1;
        }

        &.content-tile__aspect-ratio--has-media {
            //Fixes IE11 issue which renders overly tall elements if the image is large enough.
            flex-shrink: 0;
        }
    }

    .content-tile__aspect-ratio--inherit {
        min-height: 100%;
        flex-grow: 1;

        @include aspect-ratio(320, 240, true);
    }

.content-tile--style-default {
    &.content-tile--size-large {
        .content-tile__title {
            @include fluid-type(map-get($breakpoints, small), map-get($breakpoints, large), $font-size-kilo, $font-size-h3);
        }

        .content-tile__description {
            font-size: $font-size-deka;
        }
    }

    &.content-tile--size-regular {
        .content-tile__title {
            @include fluid-type(map-get($breakpoints, small), map-get($breakpoints, large), $font-size-hecto, $font-size-kilo);
        }
    }

    &.content-tile--size-small {
        .content-tile__title {
            @include fluid-type(map-get($breakpoints, small), map-get($breakpoints, large), $font-size-deka, $font-size-hecto);
        }

        .content-tile__description {
            font-size: $font-size-deci;
        }
    }

    &.content-tile--size-tiny {
        .content-tile__title {
            font-size: $font-size-base;
        }

        .content-tile__description {
            font-size: $font-size-deci;
        }
    }
}

.content-tile--style-feature {
    &.content-tile--size-large {
        .content-tile__title {
            @include fluid-type(map-get($breakpoints, small), map-get($breakpoints, large), $font-size-h5, $font-size-h1);
        }
    }

    &.content-tile--size-regular {
        .content-tile__title {
            @include fluid-type(map-get($breakpoints, small), map-get($breakpoints, large), $font-size-h5, $font-size-h3);
        }
    }

    &.content-tile--size-small {
        .content-tile__title {
            @include fluid-type(map-get($breakpoints, small), map-get($breakpoints, large), $font-size-h6, $font-size-h5);
        }
    }
}

    .content-tile__aspect-ratio--square {
        @include aspect-ratio(360, 375, true);
    }

    .content-tile__aspect-ratio--wide {
        @include aspect-ratio(7, 1, true);
    }

    .content-tile__aspect-ratio--tall {
        @include aspect-ratio(5, 7, true);
    }

    .content-tile__title,
    .content-tile__description {
        transition: opacity $transition-speed-faster $transition-ease-default;
    }

    .content-tile__actions {
        margin-right: -.25rem;
        margin-left: -.25rem;
    }

        .content-tile__cta {
            max-width: rem-calc(154);
            margin-right: .25rem;
            margin-left: .25rem;
            &:not(:last-child) {
                margin-bottom: .75rem
            }
        }

.content-tile__categories::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
}

.content-tile__categories {
    font-size: 0;
    overflow-x: auto;
    margin: 0px;
    text-align: center;
    white-space: nowrap;

    .content-tile {
        display: inline-block;
        margin: 0 rem-calc(2);
        vertical-align: top;
        width: rem-calc(123);
        white-space: normal;

        &:first-child { margin-left: 0 }
        &:last-child { margin-right: 0 }

        &:hover, &:focus {
            .component-actions__cta {
                text-decoration: underline;
            }
        }
    }

    .component-actions__cta {
        font-size: rem-calc(12);
    }
}

.content-tile__img {
    width: 100%;
}

.experience-accelerator_assets-contentTile {
    .content-tile {
        height: auto;
    }
}

@include media-query(medium-up) {
    .content-tile--style-feature {
        .content-tile__content-wrap {
            &.set--text-overlay,
            &.set--text-overlay-large {
                padding-right: 2.75rem;
                padding-left: 2.75rem;
            }
        }
    }

    .content-tile__categories {
        margin-bottom: rem-calc(16);

        .content-tile {
            margin: 0 rem-calc(16);
            width: rem-calc(158);
        }

        .component-actions__cta {
            font-size: rem-calc(16)
        }
    }
}

@include media-query(small-up-to-medium) {
    .content-tile--style-feature {
        .content-tile__content-wrap {
            &.set--text-overlay,
            &.set--text-overlay-large {
                padding-right: 2rem;
                padding-left: 2rem;
            }
        }
    }
}

@include media-query(small-up) {
    .content-tile--style-default {
        .content-tile__content-wrap {
            &.set--text-overlay,
            &.set--text-overlay-large {
                padding-right: 2rem;
                padding-left: 2rem;
            }
        }

        .content-tile__description {
            margin-top: .5em;
        }

        .content-tile__actions:not(:first-child) {
            margin-top: 1rem;
        }
    }

    .content-tile--style-feature {
        .content-tile__description {
            margin-top: .8125rem;
        }

        .content-tile__actions:not(:first-child) {
            margin-top: 2rem;
        }
    }

        .content-tile__content-wrap {
            &.set--text-overlay,
            &.set--text-overlay-large {
                padding-top: 1.875rem;
                padding-bottom: 1.875rem;
            }

            &.set--text-after,
            &.set--text-after-large {
                padding-top: 1rem;
                padding-bottom: 1rem;

                &.set--has-background {
                    padding-left: 1rem;
                    padding-right: 1rem;
                }
            }

            &.set--text-before,
            &.set--text-before-large {
                padding-top: 1rem;
                padding-bottom: 1rem;

                &.set--has-background {
                    padding-left: 1rem;
                    padding-right: 1rem;
                }
            }
        }
}

@include media-query(small) {
    .content-tile__content-wrap {
        &.set--text-overlay,
        &.set--text-overlay-small {
            padding: 1.35rem;
        }

        &.set--text-after,
        &.set--text-after-small {
            padding-top: rem-calc(8);
            padding-bottom: rem-calc(16);

            &.set--has-background {
                padding-left: .75rem;
                padding-right: .75rem;
            }
        }

        &.set--text-before,
        &.set--text-before-small {
            padding-top: .75rem;
            padding-bottom: .75rem;

            &.set--has-background {
                padding-left: .75rem;
                padding-right: .75rem;
            }
        }
    }

    .content-tile__description {
        margin-top: .35rem;
    }

    .content-tile__actions:not(:first-child) {
        margin-top: .825rem;
    }
}
