/**
 * Position definition
 *
 * Example of utilization
 * @include absolute(top 1rem left 2rem);
 */

@mixin position($position, $args: ()) {
    $offsets: top right bottom left;

    position: $position;

    @each $offset in $offsets {
        $index: index($args, $offset);

        @if $index {
            @if $index == length($args) {
                #{$offset}: 0;
            }

            @else {
                $next: nth($args, $index + 1);

                @if is-valid-value($next) {
                    #{$offset}: $next;
                }

                @else if index($offsets, $next) {
                    #{$offset}: 0;
                }

                @else {
                    @warn 'Invalid value #{$next} for offset #{$offset}';
                }
            }
        }
    }
}

@mixin absolute($args: ()) {
    @include position(absolute, $args);
}

@mixin fixed($args: ()) {
    @include position(fixed, $args);
}

@mixin relative($args: ()) {
    @include position(relative, $args);
}

@mixin sticky($args: ()) {
    @include position(sticky, $args);
}
