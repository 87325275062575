/*------------------------------------*\
  #FLEX-GRID
\*------------------------------------*/

$fg-class-grid: 'flex';
$fg-use-off-half: false;

$fg-gutter: (
    xs: $gutter-base,
    lg: $gutter-base
);

$fg-breakpoints: (
    xs: 0,
    sm: map-get($breakpoints, xsmallest),
    md: map-get($breakpoints, small) + map-get($breakpoints, unit),
    lg: map-get($breakpoints, medium) + map-get($breakpoints, unit),
    xl: map-get($breakpoints, xmedium) + map-get($breakpoints, unit)
);

@import "@reformation/ref-flex-grid/src/flex-grid";


/*------------------------------------*\
  #FLEX-HELPERS
\*------------------------------------*/
//Fixes text not wrapping properly when a child of a
//'flex' container has the 'flex-shrink: 0;' property.
//Must be applied to the 'flex' container.
.fix--overflow {
    > * {
        max-width: 100%;
    }
}

//IE Fix for col children of a parent with direction: column;
//Need to find alternative.
[class*="col-"] {
    > * {
        flex-shrink: 0;
    }

    &.flex-no-gutters {
        padding-left: 0;
        padding-right: 0;
    }

    &.col--padding-lg {
        padding-left: rem-calc(4);
        padding-right: rem-calc(4);
        
				@include media-query(small) {
					&.col--mobile-padding-sm {
						padding-left: rem-calc(2);
						padding-right: rem-calc(2);
					}
				}
		
        @include media-query(small-up) {
            padding-left: rem-calc(16);
            padding-right: rem-calc(16);
        }
    }
}

@include media-query(small-up) {
	.row.row--no-gutters {
			margin-left: 0;
			margin-right: 0;
	}
}
.row--padding-lg {
    margin-left: rem-calc(-4);
    margin-right: rem-calc(-4);

		@include media-query(small) {
			&.row--mobile-padding-sm {
				margin-left: rem-calc(-2);
				margin-right: rem-calc(-2);
			}
		}

    @include media-query(small-up) {
		margin-left: rem-calc(-16);
		margin-right: rem-calc(-16);
	}
}

//Single class to make an element 'flex';
//There are more atomic classes coming from the born-flexgrid,
// so we don't have to re-declare those.
.flex {
    display: flex;
}

.flex--inline {
    display: inline-flex;
}

.flex-grow-1 {
    flex-grow: 1;
}

.flex-shrink-1 {
	flex-shrink: 1;
}

.flex-shrink-0 {
	flex-shrink: 0;
}

.flex-wrap {
	flex-wrap: wrap;
}

.flex-nowrap {
	flex-wrap: nowrap;
}

.flex-space-between {
	justify-content: space-between;
}

.flex-justify {
	&--end {
		justify-content: end
	}
}

.flex-column {
	display: flex;
	flex-direction: column
}

@include media-query(small) {
	.m-flex-column {
		display: flex;
		flex-direction: column
	}
}

.grid {
	display: grid;
}

.block {
	display: block
}

// Order helper classes.
// Especially useful for CMS-able elements in rows.
@include generate-enumerated-class(order);

@include media-query(small-up) {
    @include generate-enumerated-class(order--small-up, order);
}

@include media-query(small) {
    @include generate-enumerated-class(order--small, order);
}

/*md

# Grid configuration

## Columns

```html_example
    <div class="row" id="columns">

	<div class="col-12">
		<div class="styleguide__grid-item"></div>
	</div>

	<div class="col-1">
		<div class="styleguide__grid-item"></div>
	</div>
	<div class="col-11">
		<div class="styleguide__grid-item"></div>
	</div>

	<div class="col-2">
		<div class="styleguide__grid-item"></div>
	</div>
	<div class="col-10">
		<div class="styleguide__grid-item"></div>
	</div>

	<div class="col-3">
		<div class="styleguide__grid-item"></div>
	</div>
	<div class="col-9">
		<div class="styleguide__grid-item"></div>
	</div>

	<div class="col-4">
		<div class="styleguide__grid-item"></div>
	</div>
	<div class="col-8">
		<div class="styleguide__grid-item"></div>
	</div>

	<div class="col-5">
		<div class="styleguide__grid-item"></div>
	</div>
	<div class="col-7">
		<div class="styleguide__grid-item"></div>
	</div>

	<div class="col-6">
		<div class="styleguide__grid-item"></div>
	</div>
	<div class="col-6">
		<div class="styleguide__grid-item"></div>
	</div>

	<div class="col-7">
		<div class="styleguide__grid-item"></div>
	</div>
	<div class="col-5">
		<div class="styleguide__grid-item"></div>
	</div>

	<div class="col-8">
		<div class="styleguide__grid-item"></div>
	</div>
	<div class="col-4">
		<div class="styleguide__grid-item"></div>
	</div>

	<div class="col-9">
		<div class="styleguide__grid-item"></div>
	</div>
	<div class="col-3">
		<div class="styleguide__grid-item"></div>
	</div>

	<div class="col-10">
		<div class="styleguide__grid-item"></div>
	</div>
	<div class="col-2">
		<div class="styleguide__grid-item"></div>
	</div>

	<div class="col-11">
		<div class="styleguide__grid-item"></div>
	</div>
	<div class="col-1">
		<div class="styleguide__grid-item"></div>
	</div>

	<div class="col-12">
		<div class="styleguide__grid-item"></div>
	</div>
</div>
```

## Column Offsets

```html_example
<div class="row" id="offsets">

	<div class="col-12">
		<div class="styleguide__grid-item"></div>
	</div>

	<div class="col-11 off-1">
		<div class="styleguide__grid-item"></div>
	</div>

	<div class="col-10 off-2">
		<div class="styleguide__grid-item"></div>
	</div>

	<div class="col-9 off-3">
		<div class="styleguide__grid-item"></div>
	</div>

	<div class="col-8 off-4">
		<div class="styleguide__grid-item"></div>
	</div>

	<div class="col-7 off-5">
		<div class="styleguide__grid-item"></div>
	</div>

	<div class="col-6 off-6">
		<div class="styleguide__grid-item"></div>
	</div>

	<div class="col-5 off-7">
		<div class="styleguide__grid-item"></div>
	</div>

	<div class="col-4 off-8">
		<div class="styleguide__grid-item"></div>
	</div>

	<div class="col-3 off-9">
		<div class="styleguide__grid-item"></div>
	</div>

	<div class="col-2 off-10">
		<div class="styleguide__grid-item"></div>
	</div>

	<div class="col-1 off-11">
		<div class="styleguide__grid-item"></div>
	</div>

</div>
```

## Responsive By Combining Namespaces
Drag browser around to test responsive breakpoints.

```html_example
<div class="row" id="responsivity">
	<div class="col-6 col-md-3 col-lg-1">
		<div class="styleguide__grid-item"></div>
	</div>
	<div class="col-6 col-md-3 col-lg-1">
		<div class="styleguide__grid-item"></div>
	</div>
	<div class="col-6 col-md-3 col-lg-1">
		<div class="styleguide__grid-item"></div>
	</div>
	<div class="col-6 col-md-3 col-lg-1">
		<div class="styleguide__grid-item"></div>
	</div>
	<div class="col-6 col-md-3 col-lg-1">
		<div class="styleguide__grid-item"></div>
	</div>
	<div class="col-6 col-md-3 col-lg-1">
		<div class="styleguide__grid-item"></div>
	</div>
	<div class="col-6 col-md-3 col-lg-1">
		<div class="styleguide__grid-item"></div>
	</div>
	<div class="col-6 col-md-3 col-lg-1">
		<div class="styleguide__grid-item"></div>
	</div>
	<div class="col-6 col-md-3 col-lg-1">
		<div class="styleguide__grid-item"></div>
	</div>
	<div class="col-6 col-md-3 col-lg-1">
		<div class="styleguide__grid-item"></div>
	</div>
	<div class="col-6 col-md-3 col-lg-1">
		<div class="styleguide__grid-item"></div>
	</div>
	<div class="col-6 col-md-3 col-lg-1">
		<div class="styleguide__grid-item"></div>
	</div>
	<div class="col-6 col-md-3 col-lg-1">
		<div class="styleguide__grid-item"></div>
	</div>
	<div class="col-6 col-md-3 col-lg-1">
		<div class="styleguide__grid-item"></div>
	</div>
	<div class="col-6 col-md-3 col-lg-1">
		<div class="styleguide__grid-item"></div>
	</div>
	<div class="col-6 col-md-3 col-lg-1">
		<div class="styleguide__grid-item"></div>
	</div>
	<div class="col-6 col-md-3 col-lg-1">
		<div class="styleguide__grid-item"></div>
	</div>
	<div class="col-6 col-md-3 col-lg-1">
		<div class="styleguide__grid-item"></div>
	</div>
	<div class="col-6 col-md-3 col-lg-1">
		<div class="styleguide__grid-item"></div>
	</div>
	<div class="col-6 col-md-3 col-lg-1">
		<div class="styleguide__grid-item"></div>
	</div>
	<div class="col-6 col-md-3 col-lg-1">
		<div class="styleguide__grid-item"></div>
	</div>
	<div class="col-6 col-md-3 col-lg-1">
		<div class="styleguide__grid-item"></div>
	</div>
	<div class="col-6 col-md-3 col-lg-1">
		<div class="styleguide__grid-item"></div>
	</div>
	<div class="col-6 col-md-3 col-lg-1">
		<div class="styleguide__grid-item"></div>
	</div>
	<div class="col-6 col-md-3 col-lg-1">
		<div class="styleguide__grid-item"></div>
	</div>
	<div class="col-6 col-md-3 col-lg-1">
		<div class="styleguide__grid-item"></div>
	</div>
	<div class="col-6 col-md-3 col-lg-1">
		<div class="styleguide__grid-item"></div>
	</div>
	<div class="col-6 col-md-3 col-lg-1">
		<div class="styleguide__grid-item"></div>
	</div>
	<div class="col-6 col-md-3 col-lg-1">
		<div class="styleguide__grid-item"></div>
	</div>
	<div class="col-6 col-md-3 col-lg-1">
		<div class="styleguide__grid-item"></div>
	</div>
	<div class="col-6 col-md-3 col-lg-1">
		<div class="styleguide__grid-item"></div>
	</div>
	<div class="col-6 col-md-3 col-lg-1">
		<div class="styleguide__grid-item"></div>
	</div>
	<div class="col-6 col-md-3 col-lg-1">
		<div class="styleguide__grid-item"></div>
	</div>
	<div class="col-6 col-md-3 col-lg-1">
		<div class="styleguide__grid-item"></div>
	</div>
	<div class="col-6 col-md-3 col-lg-1">
		<div class="styleguide__grid-item"></div>
	</div>
	<div class="col-6 col-md-3 col-lg-1">
		<div class="styleguide__grid-item"></div>
	</div>
</div>
```

 */
