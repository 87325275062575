@charset "UTF-8";

/*------------------------------------*\
  #GLOBALS
\*------------------------------------*/

@import "./setup/import";

/*------------------------------------*\
  #THIRD-PARTY-LIBS
\*------------------------------------*/
@import "normalize-scss/sass/normalize/import-now.scss";

/*------------------------------------*\
  #BASE
\*------------------------------------*/
@import "./base/scaffold";
@import "./base/grid";
@import "./base/sizing";
@import "./base/wrappers";

/*------------------------------------*\x
  #COMPONENTS
\*------------------------------------*/
@import "components/buttons";
@import "./components/links";
@import "components/icons";
@import "components/formElements";
@import "./components/tabNav";

@import "./components/header/headerCommons";
@import "./components/header/headerPromo";
@import "./components/header/headerUtilityItems"; 

@import "./components/pricing";
@import "./components/breadcrumb";


@import "./components/product/swatches";
@import "./components/product/anchors";

@import "core/components/product/applePay";

/*------------------------------------*\
  #TRUMPS
\*------------------------------------*/
@import "base/typography";
@import "~app_accelerator_core/base/visibility";

/*------------------------------------*\
  #MODULES
\*------------------------------------*/
@import "./modules/moduleCommons";
@import "./modules/productTile";
@import "./modules/vip";
@import "./modules/hero";
@import "./modules/contentTile";

@import '~int_globale_sfra/globale/styles';
