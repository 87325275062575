.price {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;

    .product-line-item--minicart &,
    .product-line-item__price--mobile & {
        justify-content: flex-start;
        margin-bottom: rem-calc(8);
    }

    .order-summary__products & {
        justify-content: flex-start;
        margin-bottom: rem-calc(8);
    }

    .is-sale + .is-sale {
        margin-left: rem-calc(8);
    }

    .strike-through {
        font-size: 1em;

        .price--formated,
        .price--reduced {
            text-decoration: line-through;
            color: $color-grey-dark;
        }
    }

    .starting,
    .range,
    .sales {
        font-weight: $font-weight-normal;
    }

    .range {
        white-space: nowrap;
        display: flex;
    }

    .price__sales,
    .price__original {
        white-space: nowrap;
    }

    .pdp__price & {
        flex-wrap: nowrap;
    }
}

.prices--quickadd-mobile .price {
    justify-content: flex-start;
}

.product-line-item__unit-price .price {
    flex-direction: column;
    align-items: flex-end;
}

.product-line-item__price--order-details {
    .price {
        justify-content: flex-start;
    }
}

.price__original + .price__sales {
    order: -1;
    margin-right: rem-calc(8);
}

.price--reduced {
    display: none;
}

.product-line-item--minicart,
.order-summary__products,
.order-confirmation__product-list,
.product-line-item--wishlist {
    .price--formated {
        display: none;
    }

    .price--reduced {
        display: inline-block;
    }
}
