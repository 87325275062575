@import "~app_accelerator_core/base/scaffold";

html {
  letter-spacing: $letter-spacing-base;
}

body {
    overflow-x: hidden;

    &.no-scroll {
        overflow: hidden;
    }
}

body * {
    letter-spacing: $letter-spacing-base;
}

.blur-up {
    &.lazyload,
    &.lazyloading {
        filter: blur(0);
    }
}
