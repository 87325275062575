.margin {
    @include property-modifiers($sizes, margin);
}

.margin-l {
    @include property-modifiers($sizes, margin-left);
}

.margin-t {
    @include property-modifiers($sizes, margin-top);
}

.margin-r {
    @include property-modifiers($sizes, margin-right);
}

.margin-b {
    @include property-modifiers($sizes, margin-bottom);
}

.margin--auto {
    margin-left: auto;
    margin-right: auto;
}

.padding {
    @include property-modifiers($sizes, padding);
}

.padding-l {
    @include property-modifiers($sizes, padding-left);
}

.padding-t {
    @include property-modifiers($sizes, padding-top);
}

.padding-r {
    @include property-modifiers($sizes, padding-right);
}
.padding-b {
    @include property-modifiers($sizes, padding-bottom);
}

.set--w {
    @include property-modifiers($percentages, width, '-');
}

.set--h {
    @include property-modifiers($percentages, height, '-');
}

.gap {
    @include property-modifiers($gap-sizes, gap, '--');
}


@include media-query(small-up) {
    .t-margin {
        @include property-modifiers($sizes, margin);
    }

    .t-margin-l {
        @include property-modifiers($sizes, margin-left);
    }

    .t-margin-t {
        @include property-modifiers($sizes, margin-top);
    }

    .t-margin-r {
        @include property-modifiers($sizes, margin-right);
    }

    .t-margin-b {
        @include property-modifiers($sizes, margin-bottom);
    }

    .t-margin--auto {
        margin-left: auto;
        margin-right: auto;
    }

    .t-margin--remove-small {
        margin: -.5rem;
    }

    .t-padding {
        @include property-modifiers($sizes, padding);
    }

    .t-padding-l {
        @include property-modifiers($sizes, padding-left);
    }

    .t-padding-t {
        @include property-modifiers($sizes, padding-top);
    }

    .t-padding-r {
        @include property-modifiers($sizes, padding-right);
    }

    .t-padding-b {
        @include property-modifiers($sizes, padding-bottom);
    }

    .t-set--w {
        @include property-modifiers($percentages, width, '-');
    }

    .t-set--h {
        @include property-modifiers($percentages, height, '-');
    }
}


@include media-query(medium-up) {
    .d-margin {
        @include property-modifiers($sizes, margin);
    }

    .d-margin-l {
        @include property-modifiers($sizes, margin-left);
    }

    .d-margin-t {
        @include property-modifiers($sizes, margin-top);
    }

    .d-margin-r {
        @include property-modifiers($sizes, margin-right);
    }

    .d-margin-b {
        @include property-modifiers($sizes, margin-bottom);
    }

    .d-margin--auto {
        margin-left: auto;
        margin-right: auto;
    }

    .d-margin--remove-small {
        margin: -.5rem;
    }

    .d-padding {
        @include property-modifiers($sizes, padding);
    }

    .d-padding-l {
        @include property-modifiers($sizes, padding-left);
    }

    .d-padding-t {
        @include property-modifiers($sizes, padding-top);
    }

    .d-padding-r {
        @include property-modifiers($sizes, padding-right);
    }

    .d-padding-b {
        @include property-modifiers($sizes, padding-bottom);
    }

    .d-set--w {
        @include property-modifiers($percentages, width, '-');
    }

    .d-set--h {
        @include property-modifiers($percentages, height, '-');
    }
}

.border-width--1 {
    border-width: rem-calc(1);
}

.border-width--2 {
    border-width: rem-calc(2);
}

.border-width--3 {
    border-width: rem-calc(3);
}

.border-width--4 {
    border-width: rem-calc(4);
}

.border-width--5 {
    border-width: rem-calc(5);
}

@include media-query(small) {
    .m-margin {
        @include property-modifiers($sizes, margin);
    }

    .m-margin-l {
        @include property-modifiers($sizes, margin-left);
    }

    .m-margin-t {
        @include property-modifiers($sizes, margin-top);
    }

    .m-margin-r {
        @include property-modifiers($sizes, margin-right);
    }

    .m-margin-b {
        @include property-modifiers($sizes, margin-bottom);
    }

    .m-padding {
        @include property-modifiers($sizes, padding);
    }

    .m-padding-l {
        @include property-modifiers($sizes, padding-left);
    }

    .m-padding-t {
        @include property-modifiers($sizes, padding-top);
    }

    .m-padding-r {
        @include property-modifiers($sizes, padding-right);
    }

    .m-padding-b {
        @include property-modifiers($sizes, padding-bottom);
    }
}
