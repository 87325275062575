/*------------------------------------*\
  #CUSTOM SETUP

  Mixins ................. custom mixins without inheriting of accelerator
  Variables .............. variables to override accelerator ones with !default
  Fonts .................. client fonts definition

\*------------------------------------*/

@import "../helpers/mixins";
@import "../base/colors";
@import "../helpers/variables";
@import "../helpers/fonts";

/*------------------------------------*\
  #ACCELERATOR BASICS

  Variables .............. default variables setting up with !default
  Misins ................. basic mixins

\*------------------------------------*/

@import "~app_accelerator_core/helpers/variables";
@import "~app_accelerator_core/helpers/mixins";

@import '../helpers/mixins/get-icon';