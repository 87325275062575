@import '~app_accelerator_core/components/formElements';

/*------------------------------------*\
  #INPUT-RESETS
\*------------------------------------*/

[type='password'],
[type='text'],
[type='tel'],
[type='email'],
[type='search'],
[type='number'],
[type='date'],
select,
textarea {
    border: solid 1px $color-black;
    height: $input-height;

    &:focus {
        &::placeholder {
            color: transparent;
        }
    }
}

[type='date'] {
    -webkit-appearance: textfield; //Override default iOS safari date field styling
    color: $color-black;
    border-radius: 0;
}

input::-webkit-date-and-time-value {
    text-align: left; //Override default iOS safari date field styling
}

.form-control-label {
    .required &:after {
        content: '*';
        color: inherit;
    }
}

[class*='form-check-label'] {
    line-height: 1.5; //Aligns label with checkbox. Intentionally not a variable.
}

.form-control--tall {
  height: $button-height;
}


/*------------------------------------*\
  #FORM-COPY
\*------------------------------------*/
.form-intro__title {
  margin-bottom: .75em;
}

.form-intro__copy {
  margin-bottom: 1.75em;
}

.form-control-disclaimer {
  margin: .75em 0;
}

.form-control-description {
  margin-top: .25em;
  font-size: $font-size-deci;
  color: $color-grey-5;
}


/*------------------------------------*\
  #FORM GROUPS
\*------------------------------------*/
.form-group {
  margin-bottom: rem-calc(10);
  min-width: 8rem;
  width: 100%;

    &.form-group--constraints, &.form-space {
        margin-bottom: rem-calc(25);
    }
}

.form-group--no-spacing {
  margin-bottom: 0;
}

.form-group--no-min-w {
    min-width: auto;
}

.form-check-list__item {
  &:not(:last-child) {
      margin-bottom: 1rem;
  }
}

//Use this to separate entire sections within a form or groups of forms.
.form-section {
    &:not(:first-child) {
        margin-top: 1.6rem;
    }

    &.hidden {
        display: none;
    }

    .font-size--8 {
        font-size: rem-calc(8);
    }
}

.new-password__requirements {
    margin-bottom: rem-calc(10);
}

@include media-query(small) {
    .form-actions--reverse-sm .row {
        flex-flow: column-reverse;

        > * {
            margin-bottom: .5rem;
        }
    }
}

/*------------------------------------*\
#FORM ACTIONS
\*------------------------------------*/

//Ensure this class is added on a block element, so that bottom/top  margins are collapsed.
.form-actions {
  margin-top: rem-calc(20);
}

/*------------------------------------*\
  #FORM CONSTRAINTS
\*------------------------------------*/

.form-control__constraints {
    display: flex;
    flex-wrap: wrap;
    margin-top: rem-calc(8);
    color: $color-grey-5;
    padding-left: 0;
}

    .form-control__constraint {
        flex: 1 1 50%;
        list-style-type: none;
    }

    .set--constraint-fail {
        color: $color-error;
    }

    .set--constraint-pass {
        color: $color-success;
    }

/*------------------------------------*\
  #CHECKBOXES AND RADIOS
\*------------------------------------*/
.form-check {
  position: relative;
}

.form-check-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.form-check-input:checked + .form-check-label--radio,
.form-check-label--radio.set--checked,
.form-check-label--radio.toggle--active {
    &:after {
        transform: scale(.6);
    }
}

.form-check-input:checked + .form-check-label,
.form-check-label.set--checked,
.form-check-label.toggle--active {
    color: inherit;

    &:before {
        background-color: $color-primary;
        border-color: $color-primary;
        height: 1rem;
        width: 1rem;
    }

    &:after {
        opacity: 1;
        transform: scale(1);
        content: url(get-icon(checkmark, $color-white));
        left: rem-calc(2);
    }
}


[class*=form-check-label]:before {
    height: rem-calc(12);
    width: rem-calc(12);
    border-color: $color-black;
    top: rem-calc(4);
}

.form-check-label--checkbox {
    padding-left: rem-calc(24);
}

[class*=form-check-label]:after {
    height: rem-calc(12);
    left: 0;
    width: rem-calc(12);
    top: rem-calc(4);
}

.form-check-label--radio {
    &:before, 
    &:after {
        height: rem-calc(14);
        width: rem-calc(14);
    }
}

.form-check-input:disabled + [class*="form-check-label"],
[class*="form-check-label"].disabled {
    color: $color-grey-dark;
}

.form-check-input:checked + [class*="form-check-label"] {
    color: $color-black;
}

.form-check-label--custom {
    display: flex;
    align-items: center;
    min-height: rem-calc(30);
    font-size: rem-calc(14);
    padding-left: rem-calc(40);


    &:before {
        background: $color-white;
        border: 0;
        height: rem-calc(30);
        width: rem-calc(30);
    }

    &:after {
        border: 0;
    }
    &.set--checked:after {
        content: url(get-icon(checkmark, $color-white));
        width: rem-calc(20);
        top: rem-calc(10);
        left: rem-calc(5);
        color: $color-white;
        background: $color-black;
    }


    &.set--checked:before {
        background: $color-grey-1;
    }
}

.form-check-label--waitlist {
    padding-left: 0;
}

.form-control--minicart-qty {
    &.product-line-item__qty-input {
        width: rem-calc(56);
    }

    &.disabled, &[disabled] {
        border-color: $color-grey-5;
    }
}

.product-line-item__remove {
    svg.icon--trashcan {
        width: rem-calc(16);
        height: rem-calc(20);

    }
}

.custom-select,
.form-control--select {
    padding-right: rem-calc(32);
    background-size: rem-calc(24) rem-calc(24); //Provide height + width separately to fix IE display.
    background-repeat: no-repeat;
    background-position: calc(100% - .2em) center;
    background-image: url(get-icon(angle-down, $color-body-copy));
    transition: color $transition-speed-faster $transition-ease-default;
    overflow-y: hidden; //Safari desktop scrollbar fix

    &:-internal-autofill-selected {
        background-image: url(get-icon(angle-down, $color-body-copy)) !important;
    }

    &[disabled],
    &[readonly] {
        pointer-events: none;
        opacity: 1;
        color: $color-grey-dark;
        border-color: $color-grey-dark;
        background-color: $color-grey-1;
        background-image: url(get-icon(angle-down, $color-grey-dark));
    }

    &-tall {
        height: rem-calc(56);
    }

    &.label-invalid-feedback {
        border-color: $color-error;
    }

    &.set--error {
        border-color: $color-error;
        color: $color-error;
        background-image: url(get-icon(angle-down, $color-error));
    }
}

.form-custom-checkbox {
    &:not(.checkout-stage__form) {
        cursor: pointer;
    }

    .form-check-label--custom {
        width: rem-calc(30);
        position: relative;
        //Safari checkbox fix
        background-color: $color-white;
        border: 0;
        &::before {
            border: 1px solid $color-black;
            position: absolute;
            top: 0;
            left: 0;
        }
        &:checked:after {
            opacity: 1;
        }
    }

    .form-check-label--small {
        height: rem-calc(12);
        margin-top: rem-calc(2);
        min-height: rem-calc(12);
        width: rem-calc(12);

        &::before {
            height: rem-calc(12);
            width: rem-calc(12);
        }

        &::after {
            font-size: $font-size-micro;
            font-weight: bold;
            top: 0;
            left: 0;
            padding: rem-calc(2);
            height: rem-calc(12);
            width: rem-calc(12);
        }
    }

    .custom-control-label {
        margin-left: rem-calc(16);
    }

    .custom-control-label--waitlist {
        margin-left: rem-calc(8);

    }

    .custom-control.flex:not(.flex-align-start) {
        align-items: flex-end;
    }
}

.form-control--textarea {
    outline: none;

    &::placeholder {
        color: $color-black;
        font-size: rem-calc(12);
    }

    &.font-size--16 {
        &::placeholder {
            font-size: rem-calc(16);
        }
    }
}

.form-control--textarea--multiline {
    height: rem-calc(128);
}

.success-feedback {
    color: $color-black;
}
.new-card-element__checkbox {
    label {
        cursor: pointer;
        display: inline-flex;
        height: rem-calc(24);
        display: flex;
        align-items: center;
    }
}

/*------------------------------------*\
  #FLOATING-LABEL-FIELDS
\*------------------------------------*/
//UNCOMMENT IF USING FLOATING LABELS
[data-floating-label] {
    position: relative;
    .form-control-label {
        display: inline-flex;
        position: absolute;
        top: rem-calc(21);
        left: rem-calc(10);
        transition: opacity $transition-speed-faster $transition-ease-default,
                    top $transition-speed-faster $transition-ease-default;
        cursor: text;
        pointer-events: none;
        font-size: rem-calc(16);
        line-height: 1.1;
        z-index: 1;

        &.disabled {
            color: $color-grey-dark;
        }

        &.form-control-label--secondary {
            left: 0;

        }
    }
    &.set--floating-label {
        .form-control-label {
            font-size: rem-calc(12);
            top: rem-calc(6);
        }
    }
    &:not(.set--floating-label) {
        .form-control--select {
            //IE doesn't like straight transparency here.
            color: transparentize($color-white, 0.99);
        }
        .form-control {
            &::placeholder {
                color: transparent;
            }
            //This seems to be required for IE.
            &:-ms-input-placeholder {
                color: transparent;
            }
        }
    }
    .form-control {
        padding: rem-calc(21) rem-calc(10) rem-calc(8);
        height: $input-height;
        font-size: rem-calc(16);
        line-height: 1.1;
        text-align: left;
        border: 1px solid $color-black;
        overflow-x: hidden;
        -webkit-transition: all 0.15s linear 0s;
        transition: all 0.15s linear 0s;

        &[disabled], &[readonly] {
            color: $color-grey-dark;
            background-color: $color-grey-1;
            border-color: $color-grey-dark;
        }

        &.form-control--secondary {
            border: none;
            border-bottom: 1px solid $color-black;
            padding: rem-calc(21) rem-calc(32) rem-calc(8) 0;
        }
        &.form-control--confirmation {
            padding: 0;
            position: absolute;
            top: 100%;
            visibility: hidden;
            opacity: 0;
            &.active {
                top: 0;
                visibility: visible;
                opacity: 100%;
                transition: all .3s ease-in;
                overflow: hidden;
            }
            &.fadeout {
                visibility: hidden;
                opacity: 0;
                transition: all .3s ease-out;
            }


        }
    }
    .form-control.is-invalid {
            border-color: $color-error;
            background-color: unset;
            color: $color-black;
        }
    .form-control--select {
        padding-right: rem-calc(28);
    }
}

.set--floating-label {
    .form-control-label--select {
        opacity: 1; //select dropdowns need this to avoid label overlay.
    }
}

.form-actions_refund-link {
    justify-content: flex-end;
    align-items: flex-end;
}

@include media-query(small) {
    input,
    select,
    textarea {
        //Intentionally set to 1 rem to force input fields have exactly 16px
        //font size, which prevents zooming in when focusing them on mobile.
        font-size: rem-calc(16) !important;
    }

    .form-actions_refund-link{
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: rem-calc(20);
    }
}
@keyframes fade-out {
    0%, 30% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}