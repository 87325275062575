
.product-tile {
    .price {
        justify-content: flex-start;
    }

    .price--formated {
        display: none;
    }

    .price--reduced {
        display: inline-block;
    }
}

.product-tile--default {
    position: relative;

    .product-tile__anchor {
        display: block;
        position: relative;
        z-index: 1;

        &.video-on-clp {
            cursor: pointer;
        }
    }
}


.product-tile--overlay {
    padding: rem-calc(20);
}

.product-tile--card {
    position: relative;
    margin-bottom: rem-calc(20);

    .product-tile__body {
        flex: 1 1 70%;
        padding-right: rem-calc(8);
        padding-left: rem-calc(8);
    }

    .product-tile__media {
        display: block;
        overflow: hidden;
        flex: 1 1 30%;

        @include aspect-ratio(1, 1, true);

    }
}

.tile-image {
    transition: opacity 0.2s ease-in-out;
}

.tile-image-primary {
    position: relative;
    z-index: 1;

    &.set--secondary-ready {
        .set--quickadd-active & {
            z-index: 0;
        }
    }
}


.tile-image-secondary {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}


    .product-tile__body {
        position: relative;
        margin-top: rem-calc(16);
        justify-content: space-between;

        .product-tile__name {
            margin-bottom: rem-calc(4);
            overflow: hidden;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;

            @include media-query(medium-up) {
                .product-grid--view-feed & {
                    -webkit-line-clamp: 1;
                }
            }
        }

        .price {
            justify-content: flex-start;
        }

        .strike-through {
            font-size: 1em;
        }

        .product__badges {
            color: $color-grey-dark;

            @include media-query(small-up) {
                line-height: rem-calc(24);
                white-space: nowrap;

                .product__badges-item {
                    margin-left: rem-calc(16);
                }
            }

            @include media-query(small) {
                .product__badges-item {
                    display: inline-flex;
                    margin-top: rem-calc(4);
                }
            }
        }

        @include media-query(small) {
            flex-direction: column;
        }
    }

    .product-tile__pd {
        .product__badges {
            @include media-query(small-up) {
                .product__badges-item {
                    margin-left: 0;
                }
            }
        }
    }

    .product-tile__media--default {
        display: block;
        overflow: hidden;

        @include aspect-ratio(1, 1.33, true);
    }

    .product-tile__anchor:focus,
    .product-tile__media--default:hover {
        .product-tile__image--secondary.lazyloaded {
           @include set-visible();
        }
    }

        .product-tile__image {
            max-height: none;
        }

        .product-tile__image--secondary {
            @include set-invisible();
        }

    .product-tile__colors-container {
        position: relative;

        .product-list__carousel & {
            @include media-query(small-up) {
                height: rem-calc(55);
            }
        }

        .search-results--view-grid & {
            height: rem-calc(18);
            margin-bottom: rem-calc(6);
        }
    }

    .product-tile__swatches {
        @include absolute(top 0 left 0 right 0);

        background: $color-white;
        display: none;
        flex-wrap: wrap;
        z-index: 2;
    }

    .product-tile__swatch {
        margin-bottom: rem-calc(4);

        &:not(:last-child) {
            margin-right: rem-calc(4);
        }
    }

    .product-tile__quickview {
        @include absolute(top 0 right 1rem);
        //This margin-top value must match the aspect ratio of the
        //product tile's media so that the CTA is aligned properly.
        margin-top: 125%;
        transform: translateY(calc(-100% - 1rem));
        opacity: 0;

        .product-tile:hover &,
        &:focus {
            @include set-invisible();
        }
    }

    .product-tile__quickadd {
        &.set--added-to-cart {
            .product-tile__quickadd-actions {
                display: block;
            }
        }
    }

        .product-tile__quickadd-container {
            @include absolute(top 0 left 0);
            width: 100%;
            padding-top: 133%;
            overflow: hidden;
        }

       .product-tile__quickadd-trigger {
            z-index: 2;
            @include absolute(top 100% left 0);
            font-size: rem-calc(12);
            height: rem-calc(56);
            align-items: center;
            width: 100%;
            background-color: $color-quickadd-bg;
            transform: translateY(1px);
            transition: transform $transition-speed-fast $transition-ease-quickadd;

            .product-grid--view-grid & {
                height: rem-calc(32);
            }

            .product-grid--view-feed & {
                @include media-query(xmedium-up) {
                    font-size: rem-calc(16);
                }
            }
        }

        .product-tile__quickadd-panel {
            z-index: 2;
            @include absolute(top 100% left 0);
            width: 100%;
            padding: 0 rem-calc(5);
            background-color: $color-quickadd-bg;
            transform: translateY(1px);
            //Allow pointer events only in this container.
            pointer-events: all;
            outline: none;
            transition: transform $transition-speed-fast $transition-ease-quickadd;

            &.set--ready {
                transform: translateY(calc(-100% + 1px));
            }

            .product-attribute__list--quickadd {
                padding: rem-calc(15);

                .product-grid--view-grid & {
                    padding: rem-calc(15) rem-calc(5);

                    @include media-query(large-up) {
                        padding: rem-calc(15) rem-calc(10);
                    }
                }
            }

            .product-grid--view-grid & {
                &.overlay-height--full {
                    height: calc(100% + 2px);
                }
            }
        }

        .product-tile__quickadd-actions {
            display: none;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background: $color-white;
            border: solid .0625rem;
            border-radius: $input-border-radius;
        }

    .product-tile__wishlist {
        @include absolute(top 0 right 0);

        display: none;
        padding: rem-calc(14);
        border-radius: 50%;
        overflow: hidden;

        .icon {
            height: 1.25rem;
            width: 1.25rem;
        }

        &.set--in-wishlist {
            .product-tile__wishlist-add {
                display: none;
            }

            .product-tile__wishlist-remove {
                display: block;
            }
        }
    }

.product-tile__wishlist-remove {
    display: none;
}

.product-tile__mobile-quickadd-icon {
    .icon--shopping-bag {
        width: rem-calc(20);
        height: rem-calc(20);
        padding: rem-calc(4);
        margin-top: rem-calc(-4);
    }
}

/** Hide waitlist and preorder labels on Page designer pages **/
.experience-component .product__badges{
    display: none;
}

.search-results:not(.search-results--hide-edit-badge) {
    .product__edit-badge:not(.hidden) + .product__status-badge {
        display: none;
    }    
}

.search-results--hide-edit-badge {
    .product__edit-badge {
        display: none;
    }

    .product__status-badge {
        display: inline-flex;
    } 
}

/** Hide badges on recommender tiles */
.product-list {
    .product__badges,
    .product__badge {
        display: none;
    }
}
    
@include media-query(medium-up) {
    .product-tile:hover {
        .product-tile__quickadd-trigger {
            transform: translateY(calc(-100% + 1px));
            transition: transform $transition-speed-fast $transition-ease-quickadd;

            @supports (-webkit-touch-callout: none) {
                transform: translateY(1px);
            }
        }

        .product-tile__wishlist {
            display: flex;
            z-index: 1;
        }
    }

    .product-tile--card {
        padding-top: rem-calc(16);
        padding-bottom: rem-calc(16);
        padding-right: $gutter-outer;
        padding-left: rem-calc(8);
    }

    .product-grid--view-grid.product-grid--stack-badge .product-tile__body {
        flex-direction: column;
    }
}

@include media-query(small-up) {
    .product-tile__desktop-button {
        display: block;
    }
    .product-tile__mobile-quickadd-button,
    .mobile-quickadd-button--over-image {
        display: none;
    }

    .product-tile__swatches:hover,
    .product-tile__colors-counter:hover + .product-tile__swatches {
        display: flex;
    }
}

@include media-query(medium) {
    .product-tile--card {
        padding: rem-calc(20);

        .product-tile__footer {
            margin-top: rem-calc(20);
        }
    }
}

@include media-query(small) {
    .product-tile__desktop-button {
        display: none;
    }
    .product-tile__mobile-quickadd-button {
        display: block;
    }

    .product-tile__name-price-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    .mobile-quickadd-button--over-image {
        display: none;
        @include absolute(top 0 left 0);
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .product-tile__body {
        margin-top: rem-calc(8);
    }

    .product__badge {
        margin-top: rem-calc(4);
    }
}
