@import '~app_accelerator_core/components/header/headerCommons';

.header {
    padding: 0 rem-calc(32);

    &:not(.header--no-menu) {
        box-shadow: none;
    }
}

.header--no-menu {
    box-shadow: none;
}

.header__logo {
    max-width: rem-calc(180);
    align-items: center;
    margin: 0;
}

.header__logo-globale {
    align-items: center;
    max-width: 8rem;
}

.header__logo-image {
    height: auto;
}

.header-promo {
    background: $color-grey-2;
    z-index: 10;
}

.header__main {
    padding-top: rem-calc(10);
    padding-bottom: rem-calc(11);
}

.header__globale {
    border-bottom: 2px solid $color-black;

    &::after{
        display: none;
    }

}

.header__hamburger-trigger  {
    padding: rem-calc(16);

    .icon {
        width: rem-calc(16);
        height: rem-calc(16);
    }

    .icon--hamburger-menu {
        width: rem-calc(20);
        height: rem-calc(17.5);
    }

    .icon--close {
        display: none;
    }

    &.flyout-toggle--active {
        .icon--hamburger-menu {
            display: none;
        }

        .icon--close {
            display: block;
        }
    }
}

.header__localization {
    cursor: pointer;

    .icon--carat-down {
        width: rem-calc(12);
        height: rem-calc(6);
    }
}

.header__top-left {
    .link, .link--secondary {
        font-size: rem-calc(16);
    }
}

.header-flyout__anchor {
    padding: 0;
}

@include media-query(medium) {
    .header {
        height: $header-height-m;
    }

    .header__main {
        height: $header-height-m;
        padding: 0;
        flex-wrap: nowrap;
        overflow: hidden;
    }

    .header__logo {
        max-width: rem-calc(130);
    }

    .header-promo {
        z-index: 2;
    }

    .header-flyout {
        @include set-invisible();
    }
}

@include media-query(small) {
    .header--no-menu {
        padding-left: rem-calc(15);
        padding-right: rem-calc(15);
    }

    .header__logo {
        max-width: rem-calc(105);
    }

    .header {
        padding: 0 rem-calc(16);
    }
}

@include media-query(small-up) {
    .header--stacked {
        padding-top: 0;
    }

    .header__logo-globale {
        max-width: unset;
    }

    .header--no-menu {
        padding: 0;
    }
}

@include media-query(medium-up) {
    .header-container {
        --headerContainerHeight: 0px;
        min-height: var(--headerContainerHeight);
    }

    .header {
        border-bottom: 2px solid $color-black;
        padding: 0;

        &.header--sticky-always {
            &.fixed--scrolled {
               position: fixed;
               top: 0;
               width: 100%;
           }
       }   
    }

    .header--no-menu {
        border: none;
        margin-bottom: 1rem;

        .header__logo {
            margin-top: 0;
        }
    }

    .header--stacked {
        padding-top: 0;
    }

    .header__main {
        align-items: end;
        padding-bottom: rem-calc(6);
        padding-top: 0;
    }

    .header__logo {
        margin-top: rem-calc(64);
        margin-bottom: rem-calc(6);
        align-items: baseline;
    }

    .header__top-left {
        padding-top: rem-calc(24);
        padding-left: 1.25rem;
        align-self: start;
    }

    .header__top-right {
        padding-top: rem-calc(24);
        padding-right: 1.25rem;
        align-self: start;
    }

    .header__middle {
        align-items: center;
    }

    .header-flyout__container {
        .header-flyout__list {
            &.level-1 {
                display: flex;
            }
        }
    
        &.level-2 {
            position: absolute;

            &:not(.mega-item--active) {
                @include set-invisible();
            }
        }
    }

    .header-flyout__anchor {
        display: block;
        padding: 0 1.25rem 0 0;

        &.level-1 {
            padding: rem-calc(10) rem-calc(8); // these values are utilized below for ::after positioning
            display: block;

            &.selected-top-nav:after {
                @include absolute(left 0 right 0 bottom .75rem);

                content: "";
                background: $color-black;
                height: 2px;
                margin: auto;
                width: calc(100% - #{rem-calc(16)});
            }
        }
    }
}

.utility-overlay:not(.toggle--active) {
    visibility: hidden;
    transform: translateX(100%);
}

.site-search__form {
    position: absolute;

    &:not(.toggle--active) {
        @include set-invisible();
        pointer-events: none;
    }
}

@include media-query(medium-up) {
    .header--no-menu .header__main {
        align-items: center;
        padding: 0;
    }

    .header__top-left a,
    .header__localization {
        &:hover, &:focus {
            text-decoration: underline;
        }
    }
    .header-error-msg {
        text-align: center;
    }
}

.header-error-close {
    position: absolute;
    top: 0;
    right: 0;
    margin: rem-calc(8) rem-calc(16);
    cursor: pointer;
}

@include media-query(xlarge-up) {
    .header__main.row {
        max-width: rem-calc(2000);
        margin: 0 auto;
    }
}
