.link,
.link--primary,
.link--secondary {
    display: inline-block;
    font-size: rem-calc(14);

    .component-actions__cta-icon {
        stroke: currentColor;
        stroke-width: .125rem;
        font-size: rem-calc(12);
        vertical-align: middle;
    }
}

.link--underline {
    text-decoration: underline;
    text-underline-offset: rem-calc(1);
    &:hover {
        text-underline-offset: rem-calc(2);
    }
}

.link--button {
    background-color:$color-grey-4;
    padding: rem-calc(12) rem-calc(16);

    color: white;
    font-weight: $font-weight-bold;
}

.link--default {
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}

.link--ellipsis {
    @include relative;

    border-bottom: 2px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
        font-size: rem-calc(13);
        margin-right: rem-calc(7);
        margin-bottom: rem-calc(3);
    }

    &.state--loading {
        pointer-events: none;

        &:after {
            @include absolute(left 100% bottom rem-calc(3));

            overflow: hidden;
            font-size: 1.25em;
            animation: ellipsis steps(4,end) 1.5s infinite;
            line-height: 1;
            content: '\2026'; /* ascii code for the ellipsis character */
            width: 0;
        }

        .icon {
            opacity: 0;
        }
    }
}

.link--border-underline {
    border-bottom: 1px solid $color-black;
    line-height: normal;
}

.link--border-underline-hover {
    border-bottom: 2px solid transparent;

    &:hover, &:focus {
        border-bottom: 2px solid $color-black;
    }
}


.link--highlight-hover {
    &:hover, &:focus {
        color: $color-primary;
    }
}

.link--flex {
    display: inline-flex;
}

/**
 *  Utility classes for buttons and pseudo buttons
 */
.cursor--pointer:hover {
    cursor: pointer;
}

.cursor--not-allowed:hover {
    cursor: not-allowed;
}

@keyframes ellipsis {
    to {
        width: 1.25em;
    }
}

@include media-query(medium-up) {
    .link--ellipsis:hover {
        border-bottom-color: $color-black;
    }
    
    .link--secondary,
    .link--underline-hover {
        &:hover, &:focus { 
            text-decoration: underline;
            text-underline-offset: rem-calc(2);
        }
    }
}

/*md

# Links configuration

## Regular

```html_example
    <a href="${'#'}" class="link">Link</a>
    <a href="${'#'}" class="link link--underline">Link Underline</a>
    <a href="${'#'}" class="link link--underline-hover">Link Underline Hover</a>
```

## Bordered

```html_example
    <a href="${'#'}" class="link link--border-underline">Link Border Underline</a>
    <a href="${'#'}" class="link link--border-underline-hover">Link Border Underline Hover</a>
```
 */
