@import "~app_accelerator_core/base/typography";

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: $font-weight-normal;
}

b,
strong,
.font-weight--semibold,
.font-weight--bold {
    font-weight: $font-weight-normal;
    font-family: $font-family-heavy;
}

.font-size {
  @include property-modifiers($font-sizes, font-size);
}

.font-size--12,
.font-size--14,
.font-size--16 {
  letter-spacing: $letter-spacing-base;
}

.letter-spacing--base {
    letter-spacing: $letter-spacing-base !important;
  }

.font-size--18,
.font-size--20,
.font-size--22,
.font-size--25,
.font-size--27,
.font-size--30,
.font-size--40,
.font-size--50,
.font-size--70,
.letter-spacing--large {
  letter-spacing: $letter-spacing-base;
}

/*------------------------------------*\
  #TEXT FAMILIES
\*------------------------------------*/

.font-family--sans {
    font-family: $font-family-sans;
}

[class*="heading-type"],
.font-family--sans-secondary {
    font-family: $font-family-sans-secondary;
}

.font-family--regular,
.font-family--medium,
.font-family--newtime {
    font-family: $font-family-sans;
}

.font-family--book,
.font-family--newtime-book {
    font-family: $font-family-book;
}

.font-family--heavy,
.font-family--newtime-heavy {
    font-family: $font-family-heavy;
}

.font-family--catalogue,
.font-family--catalogue *:not(.font-family--medium) {
    font-family: $font-family-catalogue;
    letter-spacing: normal;

    em {
        font-family: $font-family-catalogue-italic;        
    }
}

.font-family--catalogue-italic,
.font-family--catalogue-italic * {
    font-family: $font-family-catalogue-italic;
    letter-spacing: normal;
}

.line-height--tall {
    line-height: 1.75em;
}

.cms-generic-copy {
    line-height: 1.5;

    a {
        font-weight: $font-weight-normal;
        text-decoration: none;
        &:hover { opacity: 1 }
    }

    ol,
    ul {

        margin: 0 0 rem-calc(16) rem-calc(16);
        padding: 0 0 0 rem-calc(16);

        li:not(:last-child) {
            margin: 0;
        }
    }

    table {
        border-collapse: collapse;
        table-layout: fixed;
        width: 100%;
    }

    th, td {
        border: 1px solid $color-black;
        border-width: 0 0 1px 0;
        padding: rem-calc(8);
        text-align: left;
        font-size: rem-calc(14);
        line-height: 1.5em;

        &:first-child {
            border-left: 0;
            padding-left: 0;
        }

        &:last-child {
            border-right: 0;
        }
    }

    tr {
        border-spacing: 0
    }

    thead th {
        background-color: transparent;
        padding: .5rem;
        text-align: left;
        border: 0;
        border-bottom: 1px solid $color-black;
        font-weight: $font-weight-normal;
    }

    tbody td,
    tbody th {
        border-bottom: 1px solid $color-black;
        border-right: 1px solid $color-black;
        padding: rem-calc(8);
        white-space: nowrap;
        font-size: rem-calc(14);
    }
}

.font--grey-dark {
    color: $color-grey-dark;
}

.text-transform--sentence {
    &:first-letter{
        text-transform: capitalize
    }
}

@include media-query(small-up) {
    .t-font-size {
        @include property-modifiers($font-sizes, font-size);
    }
}

@include media-query(medium-up) {
    .d-font-size {
        @include property-modifiers($font-sizes, font-size);
    }
}

@include media-query(small) {
    .m-font-size {
        @include property-modifiers($font-sizes, font-size);
    }
}

/*md

# Typography

## Family

```html_example
    <div class="font-size--30 font-family--book">Font NewTime Book</div>
    <div class="font-size--30 font-family--regular">Font NewTime Regular</div>
    <div class="font-size--30 font-family--heavy">Font NewTime Heavy</div>
    <div class="font-size--30 font-family--catalogue">Font CatalogueLL Regular</div>
    <div class="font-size--30 font-family--catalogue-italic">Font CatalogueLL Italic</div>
```

## Sizes

```html_example
    <div class="font-size--70">Font Size 70</div>
    <div class="font-size--50">Font Size 50</div>
    <div class="font-size--40">Font Size 40</div>
    <div class="font-size--30">Font Size 30</div>
    <div class="font-size--27">Font Size 27</div>
    <div class="font-size--25">Font Size 25</div>
    <div class="font-size--22">Font Size 22</div>
    <div class="font-size--20">Font Size 20</div>
    <div class="font-size--18">Font Size 18</div>
    <div class="font-size--16">Font Size 16</div>
    <div class="font-size--14">Font Size 14</div>
    <div class="font-size--12">Font Size 12</div>
```

## Paragraph

```html_example
    <div class="cms-generic-copy t-set--w-50">
        <p>Lorem Ipsum is simply dummy text of <a href="" title="" class="link--default">the printing and typesetting</a> industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</p>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</p>
    </div>
```

## Lists (TBD)

```html_example
    <div class="cms-generic-copy">
        <ul>
            <li>I am the example</li>
            <li>I am the example</li>
            <li>I am the example</li>
            <li>I am the example</li>
            <li>I am the example</li>
            <li>I am the example</li>
            <li>I am the example</li>
        </ul>
    </div>

    <div class="cms-generic-copy">
        <ol>
            <li>I am the example</li>
            <li>I am the example</li>
            <li>I am the example</li>
            <li>I am the example</li>
            <li>I am the example</li>
            <li>I am the example</li>
            <li>I am the example</li>
        </ol>
    </div>
```
 */
